import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useParams, useNavigate } from 'react-router-dom';
import { IoArrowForwardOutline } from 'react-icons/io5';
import { Button, Divider, Row, Select, Space, Statistic, Typography, message } from 'antd';

import API from 'helpers/API';
import { phoneNumber } from 'utils/Formatter';
import { getOrderStatus, getShippingType } from 'utils/CodeMapper';
import { BottomButtons, HeadingH1, UnitTable } from 'components/ui';

import styles from './shipping.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState();
  const [locations, setLocations] = useState();
  const [shippings, setShippings] = useState();
  const [outgoingLocation, setOutgoingLocation] = useState();
  const [incomingLocation, setIncomingLocation] = useState();
  const [isTurnTo, setTurnTo] = useState({
    outgoing: false,
    incoming: false,
  });

  const getShippings = () => {
    // API: 주문배송 이력 조회
    API.get(`/orders/${rid}/shipping`).then(({ success, data }) => {
      setShippings(success ? data : []);
    });
  };

  const handleChangeLocation = (value) => {
    setIncomingLocation(locations.find((location) => location.rid === value));
  };

  const saveOutgoing = () => {
    // API: 주문배송 출고
    API.post(`/orders/${rid}/outgoing`, { destinationRid: incomingLocation.rid }).then(({ success }) => {
      if (success) {
        getShippings();
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  const saveIncoming = () => {
    if (incomingLocation.name === '주문고객') {
      // API: 주문배송 고객 인도
      API.post(`/orders/${rid}/handover`, { inspectionSign: 'SYSTEM' }).then(({ success }) => {
        if (success) {
          getShippings();
        } else {
          message.error('저장을 실패했습니다.');
        }
      });
      return;
    }

    // API: 주문배송 입고
    API.post(`/orders/${rid}/incoming`, { locationRid: incomingLocation.rid }).then(({ success }) => {
      if (success) {
        getShippings();
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  const moveToChecklist = () => {
    // 배송 이력 중 마지막 이력
    const lastShipping = shippings[shippings.length - 1];

    if (lastShipping.type !== 'DELIVERED') {
      message.error('배송이 완료되지 않았습니다.');
      return;
    }

    // 배송 완료된 시점이 6월 30일 이전인 건은 체크리스트 존재하지 않음
    if (moment(lastShipping.createdAt).isBefore(moment('20230701'))) {
      message.error('2023년 6월 30일 이전 주문 건은 체크리스트가 존재하지 않습니다.');
      return;
    }

    navigate(`/orders/${order.rid}/shipping/checklist`);
  };

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });

    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        setOrder(data);
        getShippings();
      }
    });
  });

  useUpdateEffect(() => {
    if (shippings === undefined) return;

    if (shippings.length === 0) {
      setTurnTo({
        outgoing: true,
        incoming: false,
      });
      return;
    }

    // 최종 배송 이력
    const lastShipping = shippings[shippings.length - 1];
    setTurnTo({
      outgoing: lastShipping.type === 'INCOMING',
      incoming: lastShipping.type === 'OUTGOING',
    });
  }, [shippings]);

  useUpdateEffect(() => {
    if (locations === undefined) return;

    if (isTurnTo.outgoing) {
      // API: 용기 상세 조회
      API.get(`/tanks/${order.orderTanks[0].tank.rid}`).then(({ success, data }) => {
        if (success) {
          setOutgoingLocation(data.location);
          setIncomingLocation(locations.find((location) => location.rid !== data.location.rid));
        }
      });
    }

    if (isTurnTo.incoming) {
      const lastShipping = shippings[shippings.length - 1];
      setOutgoingLocation(lastShipping.location);
      setIncomingLocation(lastShipping.destination);
    }
  }, [isTurnTo, locations]);

  return (
    <div className={cx('container')}>
      <HeadingH1 title={'배송상세'} />

      <Typography.Text strong className={cx('title')}>
        주문정보
      </Typography.Text>

      <Row align={'top'} justify={'space-between'} className={cx('wrapper')}>
        <Statistic title="주문번호" value={order?.originNo} groupSeparator={''} />
        <Statistic title="주문일자" value={moment(order?.orderDate, 'YYYYMMDD').format('YYYY-MM-DD')} />
        <Statistic title="상태" value={getOrderStatus(order?.status)} />
        <Statistic title="수량" value={Number(order?.quantity).toLocaleString()} />
        <Statistic title="성명" value={order?.customerName} />
        <Statistic title="회사명" value={order?.customerCompany} />
        <Statistic title="전화번호" value={order?.customerPhone} formatter={(value) => phoneNumber(value)} />
      </Row>

      <Divider />

      <Typography.Text strong className={cx('title')}>
        배송정보
      </Typography.Text>

      <Row align={'top'} justify={'space-between'} className={cx('wrapper')}>
        <Statistic title="성명" value={order?.deliveryName} />
        <Statistic title="회사명" value={order?.deliveryCompany} />
        <Statistic title="전화번호" value={order?.deliveryPhone} formatter={(value) => phoneNumber(value)} />
        <Statistic title="주소" value={`${order?.deliveryAddress} ${order?.deliveryAddressDetail || ''}`} />
      </Row>

      <Divider />

      <Typography.Text strong className={cx('title')}>
        배송이력
      </Typography.Text>

      {isTurnTo.outgoing && (
        <Space align="center">
          <Select
            name="status"
            style={{ width: 240 }}
            readOnly
            value={outgoingLocation?.rid}
            options={locations?.map((data) => ({
              label: data.name,
              value: data.rid,
              disabled: data.rid !== outgoingLocation?.rid,
            }))}
          />
          <IoArrowForwardOutline size={20} className={cx('arrow')} />
          <Select
            name="status"
            style={{ width: 240 }}
            value={incomingLocation?.rid}
            onChange={handleChangeLocation}
            options={locations?.map((data) => ({
              label: data.name,
              value: data.rid,
              disabled: data.rid === outgoingLocation?.rid,
            }))}
          />
          <Button type="primary" onClick={saveOutgoing}>
            출고
          </Button>
        </Space>
      )}
      {isTurnTo.incoming && (
        <Space align="center">
          <Select
            style={{ width: 240 }}
            readOnly
            name="status"
            value={outgoingLocation?.rid}
            options={locations?.map((data) => ({
              label: data.name,
              value: data.rid,
              disabled: data.rid !== outgoingLocation?.rid,
            }))}
          />
          <IoArrowForwardOutline size={20} className={cx('arrow')} />
          <Select
            style={{ width: 240 }}
            onChange={handleChangeLocation}
            name="status"
            value={incomingLocation?.rid}
            options={locations?.map((data) => ({
              label: data.name,
              value: data.rid,
              disabled: data.rid !== incomingLocation?.rid,
            }))}
          />
          <Button onClick={saveIncoming} type="primary">
            입고
          </Button>
        </Space>
      )}

      <UnitTable
        columns={[
          {
            key: 'seq',
            title: '순번',
            render: (_, record) => record.seq,
            width: 80,
            align: 'center',
          },
          {
            key: 'type',
            title: '유형',
            render: (_, record) => getShippingType(record.type),
            width: 120,
            align: 'center',
          },
          {
            key: 'location',
            title: '현재위치',
            render: (_, record) => record.location.name,
          },
          {
            key: 'destination',
            title: '다음거점',
            render: (_, record) => record.destination?.name ?? '-',
            width: 280,
          },
          {
            key: 'createdAt',
            title: '처리일시',
            render: (_, record) =>
              record.createdAt
                ? moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')
                : moment().format('YYYY-MM-DD HH:mm:ss'),
            width: 160,
            align: 'center',
          },
          {
            key: 'createdBy',
            title: '처리자',
            render: (_, record) => (record.createdBy ? record.createdBy.name : '-'),
            width: 80,
            align: 'center',
          },
        ]}
        rowKey="seq"
        dataSource={shippings}
      />

      <BottomButtons
        left={[
          <Button key={'movePageBack'} onClick={() => navigate(-1)}>
            뒤로
          </Button>,
        ]}
        right={[
          <Button key={'moveCheckList'} type="primary" onClick={moveToChecklist}>
            체크리스트
          </Button>,
        ]}
      />
    </div>
  );
};

export default Page;
