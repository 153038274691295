import React, { useState, useMemo } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { Table, Select } from 'antd';

import API from 'helpers/API';

import styles from './card.module.scss';

const cx = classNames.bind(styles);

const ByLocation = ({ data: tanks }) => {
  const [locations, setLocations] = useState();
  const [tankSize, setTankSize] = useState();

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });
  });

  const handleChangeTankSize = (value) => {
    setTankSize(Number(value));
  };

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '거점명',
    },
    {
      key: 'count',
      dataIndex: 'count',
      title: '용기수',
      width: 80,
      align: 'center',
    },
  ];

  const data = useMemo(() => {
    if (tanks === undefined || locations === undefined) {
      return;
    }

    const filteredTanks = tanks
      .filter((tank) => tank.useStatus !== 'INSPECTING')
      .filter((tank) => (tankSize ? tankSize === tank.size : true));

    const nextData = locations.map((location) => ({
      ...location,
      count: filteredTanks.filter((tank) => tank.location?.rid === location.rid).length,
    }));
    nextData.sort((a, b) => (a.name < b.name ? -1 : 1));
    return nextData;
  }, [tanks, locations, tankSize]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>거점별</div>
        <div className={cx('options')}>
          <Select
            style={{ width: 120 }}
            defaultValue={''}
            options={[
              {
                label: '전체',
                value: '',
              },
              {
                label: '7L',
                value: '7',
              },
              {
                label: '9L',
                value: '9',
              },
              {
                label: '10.8L',
                value: '10.8',
              },
              {
                label: '12L',
                value: '12',
              },
            ]}
            onChange={(value) => handleChangeTankSize(value)}
          />
        </div>
      </div>
      <div className={cx('body')}>
        <Table
          rowKey={'rid'}
          columns={columns}
          dataSource={data}
          virtual
          pagination={false}
          scroll={{
            x: 300,
            y: 280,
          }}
        />
      </div>
    </div>
  );
};

export default ByLocation;
