import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import moment from 'moment';
import { Table, Select, InputNumber, Space } from 'antd';

import styles from './card.module.scss';

const cx = classNames.bind(styles);

const OPTIONS = {
  TANK: '용기',
  VELVE: '밸브',
};

const ByExpireDate = ({ data: tanks }) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(OPTIONS.TANK);
  const [acceptableDays, setAcceptableDays] = useState(30);

  const handleChangeSelectOption = (value) => {
    setSelectedOption(value);
  };

  const handleChangeAcceptableDays = (value) => {
    const enteredValue = Number(value);

    if (!isNaN(enteredValue)) {
      setAcceptableDays(enteredValue);
    }
  };

  const columns = [
    {
      key: 'serialNo',
      dataIndex: 'serialNo',
      title: '일련번호',
      render: (_, record) => (selectedOption === OPTIONS.TANK ? record.serialNo : record.velveSerialNo),
    },
    {
      key: 'expireDate',
      dataIndex: 'expireDate',
      title: '사용기한',
      render: (_, record) => {
        const expireDate = selectedOption === OPTIONS.TANK ? record.expireDate : record.velveExpireDate;
        return moment(expireDate).format('YYYY-MM-DD');
      },
      width: 100,
      align: 'center',
    },
    {
      key: 'remainingDays',
      dataIndex: 'remainingDays',
      title: '잔여일수',
      width: 80,
      align: 'center',
      render: (_, record) => record.remainingDays,
    },
  ];

  const data = useMemo(() => {
    if (tanks === undefined) return;

    return (
      tanks
        // 임시, 검사, 폐기 상태 용기 제외
        .filter((tank) => !['DRAFT', 'INSPECTING', 'RETIRED'].includes(tank.useStatus))
        .map((tank) => {
          // 잔여일수 계산
          const expireDate = selectedOption === OPTIONS.TANK ? tank.expireDate : tank.velveExpireDate;
          const remainingDays = moment(expireDate).diff(moment(), 'day') + 1;

          return {
            ...tank,
            remainingDays,
          };
        })
        // 사용기한까지 잔여일 수가 입력일 이하인 데이터
        .filter((tank) => tank.remainingDays <= acceptableDays)
    );
  }, [tanks, selectedOption, acceptableDays]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>사용기한 만료</div>
        <div className={cx('options')}>
          <Space>
            <Select
              options={[
                { label: '용기', value: '용기' },
                { label: '밸브', value: '밸브' },
              ]}
              style={{ width: 80 }}
              defaultValue={'용기'}
              onChange={handleChangeSelectOption}
            />
            <Space size={6}>
              <InputNumber
                style={{ width: 60 }}
                value={acceptableDays}
                maxLength={3}
                onChange={handleChangeAcceptableDays}
              />
              <span className={cx('post')}>일 이내</span>
            </Space>
          </Space>
        </div>
      </div>
      <div className={cx('body')}>
        <Table
          onRow={(record) => {
            return {
              onClick: () => navigate(`/tanks/${record.rid}`),
            };
          }}
          columns={columns}
          scroll={{
            x: 300,
            y: 280,
          }}
          virtual
          dataSource={data}
          pagination={false}
          rowKey={'rid'}
        />
      </div>
    </div>
  );
};

export default ByExpireDate;
