import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IoArrowForwardOutline } from 'react-icons/io5';
import { Button, Divider, Row, Select, Space, Statistic, Typography, message } from 'antd';

import API from 'helpers/API';
import { getReclaimStatus, getShippingType } from 'utils/CodeMapper';

import styles from './shipping.module.scss';
import { BottomButtons, HeadingH1, UnitTable } from 'components/ui';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [reclaim, setReclaim] = useState();
  const [locations, setLocations] = useState();
  const [shippings, setShippings] = useState();
  const [outgoingLocation, setOutgoingLocation] = useState();
  const [incomingLocation, setIncomingLocation] = useState();
  const [isTurnTo, setTurnTo] = useState({
    outgoing: false,
    incoming: false,
  });

  const getShippings = () => {
    // API: 회수배송 이력 조회
    API.get(`/reclaims/${rid}/shipping`).then(({ success, data }) => {
      setShippings(success ? data : []);
    });
  };

  const handleChangeLocation = (value) => {
    setIncomingLocation(locations.find((location) => location.rid === value));
  };

  const saveOutgoing = () => {
    if (outgoingLocation.name === '주문고객') {
      const body = {
        destinationRid: incomingLocation.rid,
        inspectionSign: 'SYSTEM',
      };

      // API: 회수배송 고객 인수
      API.post(`/reclaims/${rid}/handover`, body).then(({ success }) => {
        if (success) {
          getShippings();
        } else {
          message.error('저장을 실패했습니다.');
        }
      });
      return;
    }

    // API: 회수배송 출고
    API.post(`/reclaims/${rid}/outgoing`, { destinationRid: incomingLocation.rid }).then(({ success }) => {
      if (success) {
        getShippings();
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  const saveIncoming = () => {
    // API: 회수배송 입고
    API.post(`/reclaims/${rid}/incoming`, { locationRid: incomingLocation.rid }).then(({ success }) => {
      if (success) {
        getShippings();
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  const moveToChecklist = () => {
    if (shippings.length === 0) {
      message.error('회수 이력이 존재하지 않습니다.');
      return;
    }

    // 마지막 회수 이력이 6월 30일 이전인 건은 체크리스트  존재하지 않음
    if (moment(shippings[shippings.length - 1].createdAt).isBefore(moment('20230701'))) {
      message.error('2023년 6월 30일 이전 회수 건은 체크리스트가 존재하지 않습니다.');
      return;
    }

    navigate(`/reclaims/${reclaim.rid}/shipping/checklist`);
  };

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });

    // API: 회수 상세 조회
    API.get(`/reclaims/${rid}`).then(({ success, data }) => {
      if (success) {
        setReclaim(data);
        getShippings();
      }
    });
  });

  useUpdateEffect(() => {
    if (shippings === undefined) return;

    if (shippings.length === 0) {
      setTurnTo({
        outgoing: true,
        incoming: false,
      });
      return;
    }

    // 최종 배송 이력
    const lastShipping = shippings[shippings.length - 1];
    setTurnTo({
      outgoing: lastShipping.type === 'INCOMING',
      incoming: lastShipping.type === 'OUTGOING',
    });
  }, [shippings]);

  useUpdateEffect(() => {
    if (locations === undefined) return;

    if (isTurnTo.outgoing) {
      // API: 용기 상세 조회
      API.get(`/tanks/${reclaim.reclaimTanks[0].tank.rid}`).then(({ success, data }) => {
        if (success) {
          setOutgoingLocation(data.location);
          setIncomingLocation(locations.find((location) => location.rid !== data.location.rid));
        }
      });
    }

    if (isTurnTo.incoming) {
      const lastShipping = shippings[shippings.length - 1];
      setOutgoingLocation(lastShipping.location);
      setIncomingLocation(lastShipping.destination);
    }
  }, [isTurnTo, locations]);

  return (
    <div className={cx('container')}>
      <HeadingH1 title={'배송상세'} />

      <Typography.Text strong className={cx('title')}>
        회수정보
      </Typography.Text>

      <Row align={'top'} justify={'space-between'} className={cx('wrapper')}>
        <Statistic title="상태" value={getReclaimStatus(reclaim?.status)} />
        <Statistic title="수량" value={Number(reclaim?.quantity).toLocaleString()} />
        <Statistic title="성명" value={reclaim?.customerName} />
        <Statistic title="회사명" value={reclaim?.customerCompany} />
        <Statistic title="전화번호" value={reclaim?.customerPhone} />
        <Statistic title="주소" value={`${reclaim?.customerAddress} ${reclaim?.customerAddressDetail}`} />
      </Row>

      <Divider />

      <Typography.Text strong className={cx('title')}>
        배송이력
      </Typography.Text>

      {isTurnTo.outgoing && (
        <Space>
          <Select
            width={240}
            name="status"
            readOnly
            value={outgoingLocation?.rid}
            options={locations?.map((data) => ({
              label: data.name,
              value: data.rid,
              disabled: data.rid !== outgoingLocation?.rid,
            }))}
          />
          <IoArrowForwardOutline size={20} className={cx('arrow')} />
          <Select
            width={240}
            name="status"
            value={incomingLocation?.rid}
            onChange={handleChangeLocation}
            options={locations?.map((data) => ({
              label: data.name,
              value: data.rid,
              disabled: data.rid === outgoingLocation?.rid,
            }))}
          />
          <Button type="primary" onClick={saveOutgoing}>
            출고
          </Button>
        </Space>
      )}
      {isTurnTo.incoming && (
        <Space>
          <Select
            width={240}
            name="status"
            readOnly
            value={outgoingLocation?.rid}
            options={locations?.map((data) => ({
              label: data.name,
              value: data.rid,
              disabled: data.rid !== outgoingLocation?.rid,
            }))}
          />
          <IoArrowForwardOutline size={20} className={cx('arrow')} />
          <Select
            width={240}
            name="status"
            value={incomingLocation?.rid}
            onChange={handleChangeLocation}
            options={locations?.map((data) => ({
              label: data.name,
              value: data.rid,
              disabled: data.rid !== incomingLocation?.rid,
            }))}
          />
          <Button type="primary" onClick={saveIncoming}>
            입고
          </Button>
        </Space>
      )}

      <UnitTable
        dataSource={shippings}
        rowKey="seq"
        columns={[
          {
            key: 'seq',
            title: '순번',
            render: (item) => item.seq,
            width: 80,
            align: 'center',
          },
          {
            key: 'type',
            title: '유형',
            render: (item) => getShippingType(item.type),
            width: 120,
            align: 'center',
          },
          {
            key: 'location',
            title: '현재 위치',
            render: (item) => item.location.name,
          },
          {
            key: 'destination',
            title: '다음 거점',
            render: (item) => item.destination?.name ?? '-',
          },
          {
            key: 'createdAt',
            title: '처리일시',
            render: (item) =>
              item.createdAt
                ? moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')
                : moment().format('YYYY-MM-DD HH:mm:ss'),
            width: 160,
            align: 'center',
          },
          {
            key: 'createdBy',
            title: '처리자',
            render: (item) => (item.createdBy ? item.createdBy.name : '-'),
            width: 80,
            align: 'center',
          },
        ]}
      />

      <BottomButtons
        left={[
          <Button key={'movePageBack'} onClick={() => navigate(-1)}>
            뒤로
          </Button>,
        ]}
        right={[
          <Button key={'moveCheckList'} type="primary" onClick={moveToChecklist}>
            체크리스트
          </Button>,
        ]}
      />
    </div>
  );
};

export default Page;
