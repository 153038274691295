import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Form, Divider, Input, Row, Space, Button, message, Col, Flex, Statistic } from 'antd';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './new.module.scss';
import API from '../../helpers/API';
import { isEmail } from '../../utils/FormChecker';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [post, setPost] = useState();

  useMountEffect(() => {
    API.get(`/posts/${rid}`).then(({ success, data }) => {
      if (success) {
        setPost({ ...data, emails: data.emails?.join('\n') ?? '' });
      }
    });
  });

  const handleChange = (e) => {
    setPost({
      ...post,
      [e.target.name]: e.target.value,
    });
  };

  const doRemove = () => {
    if (!window.confirm('공지사항을 삭제하시겠습니까?')) {
      return;
    }

    API.delete(`/posts/${rid}`).then(({ success }) => {
      if (success) {
        navigate('/posts');
      } else {
        message.error('삭제를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    if (post.title.trim() === '') {
      message.error('제목을 입력해 주세요.');
      return false;
    }
    if (post.body.trim() === '') {
      message.error('내용을 입력해 주세요.');
      return false;
    }

    // 잘못된 이메일 주소 색출
    const invalidEmail = post.emails
      .trim()
      .split('\n')
      .map((email) => email.trim())
      .find((email) => isEmail(email) === false);

    if (invalidEmail) {
      message.error('올바른 이메일 주소를 입력해 주세요.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      title: post.title,
      body: post.body,
      emails: post.emails
        .trim()
        .split('\n')
        .map((email) => email.trim()),
    };

    API.patch(`/posts/${rid}`, data).then(({ success }) => {
      if (success) {
        navigate('/posts');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  if (post === undefined) return;

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>공지사항 상세</h1>
      <div className={cx('contentWrapper')}>
        <Form layout="vertical">
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            기본정보
          </Divider>
          <Flex vertical style={{ maxWidth: 640 }}>
            <Form.Item label="제목" labelCol={{ span: 50 }} required>
              <Input name="title" placeholder="제목" value={post.title} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="내용" required>
              <Input.TextArea
                name="body"
                placeholder="내용"
                value={post.body}
                rows={5}
                onChange={handleChange}
                className={cx('textArea')}
              />
            </Form.Item>
            <Form.Item label="수신 이메일">
              <Input.TextArea
                name="emails"
                placeholder="각 이메일 주소 줄바꿈 구분"
                value={post.emails}
                rows={5}
                onChange={handleChange}
                className={cx('textArea')}
              />
            </Form.Item>
          </Flex>
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            부가정보
          </Divider>
          <Row>
            <Col span={12}>
              <Space direction="vertical">
                <Space.Compact>
                  <Form.Item label="등록자">
                    <Statistic value={post.createdBy?.name} />
                  </Form.Item>
                  <Form.Item label="등록일시">
                    <Statistic value={dayjs(post.createdAt).format('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Space.Compact>
                <Space.Compact>
                  <Form.Item label="수정자">
                    <Statistic value={post.updatedBy?.name} />
                  </Form.Item>
                  <Form.Item label="수정일시">
                    <Statistic value={dayjs(post.updatedAt).format('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Space.Compact>
              </Space>
            </Col>
          </Row>
        </Form>
      </div>
      <Row align="middle" justify={'space-between'}>
        <Space>
          <Button onClick={() => navigate(-1)}>뒤로</Button>
          <Button danger type="primary" onClick={doRemove}>
            삭제
          </Button>
        </Space>
        <Button type="primary" onClick={doSave}>
          저장
        </Button>
      </Row>
    </div>
  );
};

export default Page;
