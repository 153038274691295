import classNames from 'classnames/bind';
import { Row, Space } from 'antd';

import styles from './bottomButton.module.scss';

const cx = classNames.bind(styles);

const BottomButtons = ({ left, right }) => {
  return (
    <Row align={'middle'} justify={'space-between'} className={cx('container')}>
      <div>
        <Space>{left?.map((ui) => ui)}</Space>
      </div>
      <div>
        <Space>{right?.map((ui) => ui)}</Space>
      </div>
    </Row>
  );
};

export default BottomButtons;
