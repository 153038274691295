import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { Select, Table } from 'antd';

import styles from './card.module.scss';

const cx = classNames.bind(styles);

const ByCustomer = ({ data: tanks }) => {
  const [tankSize, setTankSize] = useState();

  const handleChangeTankSize = (value) => {
    setTankSize(Number(value));
  };

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '주문고객명',
    },
    {
      key: 'count',
      dataIndex: 'count',
      title: '용기수',
      width: 80,
      align: 'center',
    },
  ];

  const data = useMemo(() => {
    if (tanks === undefined) return;

    // 선택된 사이즈의 용기
    const filteredTanks = tanks.filter((tank) => (tankSize ? tankSize === tank.size : true));

    const nextCustomers = [];
    filteredTanks.forEach((tank) => {
      if (tank.deliveryInfo === undefined) return;

      const found = nextCustomers.find((customer) => {
        return customer.name === tank.deliveryInfo.deliveryCompany;
      });
      if (found) {
        found.count += 1;
      } else {
        nextCustomers.push({
          name: tank.deliveryInfo.deliveryCompany,
          count: 1,
        });
      }
    });

    nextCustomers.sort((a, b) => (a.name < b.name ? -1 : 1));
    return nextCustomers;
  }, [tanks, tankSize]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>고객별</div>
        <div className={cx('options')}>
          <Select
            style={{ width: 120 }}
            defaultValue={''}
            options={[
              {
                label: '전체',
                value: '',
              },
              {
                label: '7L',
                value: '7',
              },
              {
                label: '9L',
                value: '9',
              },
              {
                label: '10.8L',
                value: '10.8',
              },
              {
                label: '12L',
                value: '12',
              },
            ]}
            onChange={(value) => handleChangeTankSize(value)}
          />
        </div>
      </div>
      <div className={cx('body')}>
        <Table
          columns={columns}
          scroll={{
            x: 300,
            y: 280,
          }}
          virtual
          dataSource={data}
          pagination={false}
          rowKey={'name'}
        />
      </div>
    </div>
  );
};

export default ByCustomer;
