import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import { ReloadOutlined } from '@ant-design/icons';
import { Row, Space, Button, Select, Table, Input, message } from 'antd';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import commaNumber from 'comma-number';
import dayjs from 'dayjs';

import styles from './index.module.scss';
import { getTankUseStatus, getTankChargeStatus } from '../../utils/CodeMapper';
import { downloadExcelFile } from 'utils/FileDownloader';
import Print from 'pages/tanks/Print';
import { UnitTable } from 'components/ui';
import CodePanel from 'components/ui/CodePanel';
import API from 'helpers/API';

const cx = classNames.bind(styles);

const sortFunc = (a, b) => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;

  return a.localeCompare(b);
};

const Page = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tanks, setTanks] = useState();
  const [codeForPanel, setCodeForPanel] = useState(null);
  const [showPaper, setShowPaper] = useState(false);
  const [selectedTanks, setSelectedTanks] = useState([]);
  const [filters, setFilters] = useState({
    useStatus: searchParams.get('useStatus') ?? null,
    chargeStatus: searchParams.get('chargeStatus') ?? null,
    velveManufacturer: searchParams.get('velveManufacturer') ?? null,
    customer: searchParams.get('customer') ?? null,
  });

  useEffect(() => {
    search();
  }, [location]);

  const search = async () => {
    // API: 용기 내역 조회
    API.get('/tanks').then(({ success, data }) => {
      if (success) {
        setTanks(data.tanks);
      }
    });
  };

  const searchWithParams = () => {
    const nextSearchParams = {};
    Object.keys(filters).forEach((key) => {
      // 필터 값 존재 시
      if (filters[key]) {
        // 검색 파라미터 정의
        nextSearchParams[key] = filters[key];
      }
    });

    setSearchParams(nextSearchParams);
  };

  const resetFilters = () => {
    setSearchParams({});
    setFilters({ useStatus: null, chargeStatus: null, customer: null, velveManufacturer: null });
  };

  const handleChangeFilter = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  // 일괄 삭제
  const doRemove = () => {
    if (selectedTanks.length === 0) {
      message.error('선택된 용기가 없습니다.');
      return;
    }

    if (!window.confirm('용기를 삭제하시겠습니까?')) {
      return;
    }

    const data = { rids: selectedTanks.map((tank) => tank.rid) };

    API.delete('/tanks', { data }).then(({ success }) => {
      if (success) {
        window.location.reload();
      } else {
        message.error('삭제를 실패했습니다.');
      }
    });
  };

  // 일괄 폐기
  const doRetire = () => {
    if (selectedTanks.length === 0) {
      message.error('선택된 용기가 없습니다.');
      return;
    }

    if (!window.confirm('용기를 폐기하시겠습니까?')) {
      return;
    }

    API.patch(`/tanks/retire`, { rids: selectedTanks.map((tank) => tank.rid) }).then(({ success }) => {
      if (success) {
        window.location.reload();
      } else {
        message.error('폐기를 실패했습니다.');
      }
    });
  };

  const preparePrint = () => {
    if (selectedTanks.length === 0) {
      message.error('선택된 용기가 없습니다.');
      return;
    }

    setShowPaper(true);
  };

  const moveToForm = () => {
    navigate('/tanks/new');
  };

  const download = () => {
    const records = filteredTanks.map((tank) => {
      return {
        A: tank.qrcode,
        B: getTankUseStatus(tank.useStatus),
        C: getTankChargeStatus(tank.chargeStatus),
        D: tank.location?.name ?? '',
        E: tank.type,
        F: tank.size ? `${tank.size}L` : '',
        G: tank.region,
        H: tank.serialNo,
        I: tank.manufacturer,
        J: tank.velveSerialNo,
        K: tank.velveManufacturer,
        L: tank.velveVersion,
        M: 'MS가스',
        N: tank.chargeCount,
        O: tank.deliveryInfo ? tank.deliveryInfo.deliveryCompany : '',
        P: tank.deliveryInfo ? dayjs(tank.deliveryInfo.deliveredAt).format('YYYY-MM-DD') : '',
        Q: tank.deliveryInfo ? dayjs().diff(dayjs(tank.deliveryInfo.deliveredAt), 'day') + 1 : '',
        R: tank.manufacturedDate,
        S: tank.expireDate ? dayjs(tank.expireDate, 'YYYYMMDD').format('YYYY-MM-DD') : '',
        T: tank.velveExpireDate ? dayjs(tank.velveExpireDate, 'YYYYMMDD').format('YYYY-MM-DD') : '',
        // U: tank.retiredAt ? dayjs(tank.retiredAt).format('YYYYMMDDHHmmss') : '',
        // V: tank.retiredBy ? tank.retiredBy.name : '',
        W: tank.memo,
      };
    });

    downloadExcelFile(
      [
        {
          A: 'QR코드',
          B: '가용상태',
          C: '충전상태',
          D: '현재 위치',
          E: '유형',
          F: '용량',
          G: '지역',
          H: '용기 일련번호',
          I: '용기 제조사',
          J: '밸브 일련번호',
          K: '밸브 제조사',
          L: '밸브 버전',
          M: '충전소',
          N: '충전횟수',
          O: '주문고객',
          P: '배송일자',
          Q: '보유일수',
          R: '제조일자',
          S: '용기 충전기한',
          T: '밸브 충전기한',
          // U: '폐기일시',
          // V: '폐기자',
          W: '메모',
        },
      ],
      records,
      `용기내역-${dayjs().format('YYYYMMDDHHmmss')}`,
    );
  };

  const columns = [
    {
      title: 'QR코드',
      key: 'qrcode',
      dataIndex: 'qrcode',
      width: 100,
      align: 'center',
      fixed: true,
      onCell: (record) => ({ onClick: () => setCodeForPanel(record.qrcode) }),
      sorter: (a, b) => a.qrcode.localeCompare(b.qrcode),
    },
    {
      title: '가용상태',
      key: 'useStatus',
      dataIndex: 'useStatus',
      width: 80,
      align: 'center',
      fixed: true,
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => getTankUseStatus(a.useStatus).localeCompare(getTankUseStatus(b.useStatus)),
      render: (_, record) => getTankUseStatus(record.useStatus),
    },
    {
      title: '충전상태',
      key: 'chargeStatus',
      dataIndex: 'chargeStatus',
      width: 80,
      align: 'center',
      fixed: true,
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => getTankChargeStatus(a.chargeStatus).localeCompare(getTankChargeStatus(b.chargeStatus)),
      render: (_, record) => getTankChargeStatus(record.chargeStatus),
    },
    {
      title: '현재 위치',
      key: 'location',
      dataIndex: 'location',
      width: 160,
      align: 'center',
      fixed: true,
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.location?.name, b.location?.name),
      render: (_, record) => record.location?.name ?? '-',
    },
    {
      title: '유형',
      key: 'type',
      dataIndex: 'type',
      width: 80,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.type, b.type),
      render: (_, record) => record.type ?? '-',
    },
    {
      title: '용량',
      key: 'size',
      dataIndex: 'size',
      width: 80,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => a.size - b.size,
      render: (_, record) => (record.size ? `${record.size}L` : '-'),
    },
    {
      title: '지역',
      key: 'region',
      dataIndex: 'region',
      width: 80,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.region, b.region),
      render: (_, record) => record.region ?? '-',
    },
    {
      title: '용기 일련번호',
      key: 'serialNo',
      dataIndex: 'serialNo',
      width: 200,
      ellipsis: true,
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.serialNo, b.serialNo),
      render: (_, record) => record.serialNo ?? '-',
    },
    {
      title: '용기 제조사',
      key: 'manufacturer',
      dataIndex: 'manufacturer',
      width: 120,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.manufacturer, b.manufacturer),
      render: (_, record) => record.manufacturer ?? '-',
    },
    {
      title: '밸브 일련번호',
      key: 'velveSerialNo',
      dataIndex: 'velveSerialNo',
      width: 200,
      ellipsis: true,
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.velveSerialNo, b.velveSerialNo),
      render: (_, record) => record.velveSerialNo ?? '-',
    },
    {
      title: '밸브 제조사',
      key: 'velveManufacturer',
      dataIndex: 'velveManufacturer',
      width: 120,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.velveManufacturer, b.velveManufacturer),
      render: (_, record) => record.velveManufacturer ?? '-',
    },
    {
      title: '밸브 버전',
      key: 'velveVersion',
      dataIndex: 'velveVersion',
      width: 100,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.velveVersion, b.velveVersion),
      render: (_, record) => record.velveVersion ?? '-',
    },
    {
      title: '충전소',
      key: 'chargeStation',
      dataIndex: 'chargeStation',
      width: 80,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      render: () => 'MS가스',
    },
    {
      title: '충전횟수',
      key: 'chargeCount',
      dataIndex: 'chargeCount',
      width: 80,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => a.chargeCount - b.chargeCount,
      render: (_, record) => commaNumber(record.chargeCount),
    },
    {
      title: '주문고객',
      key: 'customer',
      dataIndex: 'customer',
      ellipsis: true,
      width: 160,
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.deliveryInfo?.deliveryCompany, b.deliveryInfo?.deliveryCompany),
      render: (_, record) => (record.deliveryInfo ? record.deliveryInfo.deliveryCompany : ''),
    },
    {
      title: '배송일자',
      key: 'deliveredAt',
      dataIndex: 'deliveredAt',
      width: 120,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.deliveryInfo?.deliveredAt, b.deliveryInfo?.deliveredAt),
      render: (_, record) => (record.deliveryInfo ? dayjs(record.deliveryInfo.deliveredAt).format('YYYY-MM-DD') : '-'),
    },
    {
      title: '보유일수',
      key: 'dayCount',
      dataIndex: 'dayCount',
      width: 80,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => dayjs(b.deliveryInfo?.deliveredAt).diff(dayjs(a.deliveryInfo?.deliveredAt)),
      render: (_, record) =>
        record.deliveryInfo ? dayjs().diff(dayjs(record.deliveryInfo.deliveredAt), 'day') + 1 : '-',
    },
    {
      title: '제조일자',
      key: 'manufacturedDate',
      dataIndex: 'manufacturedDate',
      width: 120,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.manufacturedDate, b.manufacturedDate),
      render: (_, record) =>
        record.manufacturedDate ? dayjs(record.manufacturedDate, 'YYYYMMDD').format('YYYY-MM-DD') : '-',
    },
    {
      title: '용기 충전기한',
      key: 'expireDate',
      dataIndex: 'expireDate',
      width: 140,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => a.expireDate - b.expireDate,
      render: (_, record) => (record.expireDate ? dayjs(record.expireDate, 'YYYYMMDD').format('YYYY-MM-DD') : '-'),
    },
    {
      title: '밸브 충전기한',
      key: 'velveExpireDate',
      dataIndex: 'velveExpireDate',
      width: 140,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => a.velveExpireDate - b.velveExpireDate,
      render: (_, record) =>
        record.velveExpireDate ? dayjs(record.velveExpireDate, 'YYYYMMDD').format('YYYY-MM-DD') : '-',
    },
    {
      title: '용기 점검일자',
      key: 'checkDate',
      dataIndex: 'checkDate',
      width: 140,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => a.manufacturedDate - b.manufacturedDate,
      render: (_, record) => {
        if (record.manufacturedDate === null) {
          return '-';
        }

        let checkDate = dayjs(record.manufacturedDate, 'YYYYMMDD');
        while (checkDate.isBefore(dayjs())) {
          checkDate = checkDate.add(5, 'year');
        }

        if (checkDate.format('YYYYMM') === dayjs().format('YYYYMM')) {
          return <span style={{ color: 'red' }}>{checkDate.format('YYYY-MM-DD')}</span>;
        } else {
          return checkDate.format('YYYY-MM-DD');
        }
      },
    },
    {
      title: '밸브 점검일자',
      key: 'velveCheckDate',
      dataIndex: 'velveCheckDate',
      width: 140,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => a.velveExpireDate - b.velveExpireDate,
      render: (_, record) => {
        if (record.velveExpireDate === null) {
          return '-';
        }
        let velveCheckDate = dayjs(record.velveExpireDate, 'YYYYMMDD');
        while (velveCheckDate.isBefore(dayjs())) {
          velveCheckDate = velveCheckDate.add(1, 'years');
        }
        if (velveCheckDate.format('YYYYMM') === dayjs().format('YYYYMM')) {
          return <span style={{ color: 'red' }}>{velveCheckDate.format('YYYY-MM-DD')}</span>;
        } else {
          return velveCheckDate.format('YYYY-MM-DD');
        }
      },
    },
    {
      title: '등록일시',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 170,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (_, record) => dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '등록자',
      key: 'createdBy',
      dataIndex: 'createdBy',
      width: 100,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => a.createdBy.name.localeCompare(b.createdBy.name),
      render: (_, record) => record.createdBy.name,
    },
    {
      title: '수정일시',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      width: 170,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (_, record) => dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '수정자',
      key: 'updatedBy',
      dataIndex: 'updatedBy',
      width: 100,
      align: 'center',
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => a.updatedBy.name.localeCompare(b.updatedBy.name),
      render: (_, record) => record.updatedBy.name,
    },
    {
      title: '메모',
      key: 'memo',
      dataIndex: 'memo',
      width: 170,
      ellipsis: true,
      onCell: (record) => ({ onClick: () => navigate(`/tanks/${record.rid}`) }),
      sorter: (a, b) => sortFunc(a.memo, b.memo),
    },
  ];

  const filteredTanks = useMemo(() => {
    if (tanks === undefined) return;

    return tanks
      .filter((tank) => {
        const useStatus = searchParams.get('useStatus') ?? '';

        if (useStatus !== '') {
          return tank.useStatus === useStatus;
        }
        return true;
      })
      .filter((tank) => {
        const chargeStatus = searchParams.get('chargeStatus') ?? '';

        if (chargeStatus !== '') {
          return tank.chargeStatus === chargeStatus;
        }
        return true;
      })
      .filter((tank) => {
        const velveManufacturer = searchParams.get('velveManufacturer') ?? '';

        if (velveManufacturer !== '') {
          return tank.velveManufacturer === velveManufacturer;
        }
        return true;
      })
      .filter((tank) => {
        const customer = searchParams.get('customer')?.trim() ?? '';

        if (customer !== '') {
          return tank.deliveryInfo?.deliveryCompany.toLowerCase().includes(customer.toLowerCase());
        }
        return true;
      });
  }, [tanks, location]);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedTanks(selectedRows);
    },
    columnWidth: 32,
    fixed: true,
  };

  if (tanks === undefined) return;

  return (
    <div className={cx('container')}>
      <h2 className={cx('title')}>용기 관리</h2>
      <Row align="middle" justify={'space-between'} className={cx('controlbar')}>
        <Space>
          <div className={cx('total')}>{filteredTanks && `총 ${commaNumber(filteredTanks.length)}`}건</div>
          <Select
            placeholder="가용상태"
            value={filters.useStatus}
            options={[
              { value: 'DRAFT', label: '임시' },
              { value: 'READY', label: '준비' },
              { value: 'INUSE', label: '사용' },
              { value: 'INSPECTING', label: '검사' },
              { value: 'RETIRED', label: '폐기' },
              { value: 'RECALL', label: '리콜' },
              { value: 'DISABLED', label: '사용중지' },
            ]}
            onChange={(value) => handleChangeFilter('useStatus', value)}
            style={{ width: 150 }}
          />
          <Select
            placeholder="충전상태"
            value={filters.chargeStatus}
            options={[
              { value: 'CHARGED', label: '충전' },
              { value: 'UNCHARGED', label: '미충전' },
            ]}
            onChange={(value) => handleChangeFilter('chargeStatus', value)}
            style={{ width: 150 }}
          />
          <Select
            placeholder="밸브 제조사"
            value={filters.velveManufacturer}
            options={[
              { value: '태광', label: '태광' },
              { value: '영도', label: '영도' },
            ]}
            onChange={(value) => handleChangeFilter('velveManufacturer', value)}
            style={{ width: 150 }}
          />
          <Input
            placeholder="주문고객"
            value={filters.customer}
            onChange={(e) => handleChangeFilter('customer', e.target.value)}
          />
          <Button type="primary" onClick={searchWithParams}>
            조회
          </Button>
          <Button
            icon={<ReloadOutlined />}
            onClick={resetFilters}
            htmlType="button"
            type="primary"
            className={cx('reset')}>
            초기화
          </Button>
        </Space>
        <Space>
          <Button type="primary" onClick={download}>
            엑셀 다운로드
          </Button>
          <Button type="primary" onClick={moveToForm}>
            신규
          </Button>
        </Space>
      </Row>

      <UnitTable
        columns={columns}
        dataSource={filteredTanks}
        rowSelection={{ ...rowSelection }}
        rowKey={(record) => record.rid}
        virtual={false}
        sticky={true}
      />
      <CodePanel code={codeForPanel} close={() => setCodeForPanel(null)} />
      <Row align="middle" justify={'space-between'}>
        <Space>
          <Button danger type="primary" onClick={doRemove}>
            삭제
          </Button>
          <Button danger type="primary" onClick={doRetire}>
            폐기
          </Button>
        </Space>
        <Space>
          <Button type="primary" onClick={preparePrint}>
            QR코드 출력
          </Button>
        </Space>
      </Row>
      {showPaper && <Print data={selectedTanks} close={() => setShowPaper(false)} />}
    </div>
  );
};

export default Page;
