import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import useMountEffect from '@restart/hooks/useMountEffect';
import { Form, Divider, Input, Row, Flex, Button, Space, Col, message, Select, Statistic } from 'antd';

import styles from './new.module.scss';
import API from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState();

  useMountEffect(() => {
    // API: 유저 상세 조회
    API.get(`/users/${rid}`).then(({ success, data }) => {
      if (success) {
        setUser(data);
      }
    });
  });

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const doRemove = () => {
    if (!window.confirm('사용자를 삭제하시겠습니까?')) {
      return;
    }

    API.delete(`/users/${rid}`).then(({ success }) => {
      if (success) {
        navigate('/users');
      } else {
        message.error('삭제를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    if (user.name.trim() === '') {
      message.error('성명을 입력해 주세요.');
      return false;
    }
    if (user.role.trim() === '') {
      message.error('역할을 입력해 주세요.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      name: user.name,
      role: user.role,
      email: user.email,
      phone: user.phone,
      memo: user.memo,
    };

    API.patch(`/users/${rid}`, data).then(({ success }) => {
      if (success) {
        navigate('/users');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  if (user === undefined) return;

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>사용자 상세</h1>
      <div className={cx('contentWrapper')}>
        <Form layout="vertical">
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            기본정보
          </Divider>
          <Flex vertical style={{ maxWidth: 640 }}>
            <Form.Item label="아이디" required>
              <Input name="id" placeholder="아이디" value={user.id} readOnly style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="성명" required>
              <Input name="name" placeholder="성명" value={user.name} onChange={handleChange} style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="역할" required>
              <Select
                placeholder="선택"
                name="role"
                value={user.role}
                onChange={(value) => setUser({ ...user, role: value })}
                options={[
                  { value: 'SYSTEM', label: '시스템관리자' },
                  { value: 'ADMIN', label: '통합관리자' },
                  { value: 'MANAGER', label: '충전관리자' },
                  { value: 'CHARGER', label: '충전원' },
                  { value: 'DELIVERER', label: '배송원' },
                  { value: 'SALES', label: '영업사원' },
                ]}
                style={{ width: 300 }}
              />
            </Form.Item>
            <Form.Item label="이메일">
              <Input
                name="email"
                placeholder="이메일"
                value={user.email}
                onChange={handleChange}
                style={{ width: 300 }}
              />
            </Form.Item>
            <Form.Item label="전화번호">
              <Input
                name="phone"
                placeholder="전화번호"
                value={user.phone}
                onChange={handleChange}
                style={{ width: 300 }}
              />
            </Form.Item>
          </Flex>
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            부가정보
          </Divider>
          <Row align="top" style={{ maxWidth: 640 }}>
            <Col span={12}>
              <Form.Item label="메모">
                <Input.TextArea
                  name="memo"
                  value={user.memo}
                  onChange={handleChange}
                  placeholder="메모"
                  rows={5}
                  className={cx('textArea')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <Space.Compact>
                  <Form.Item label="등록자">
                    <Statistic value={user.createdBy?.name} />
                  </Form.Item>
                  <Form.Item label="등록일시">
                    <Statistic value={dayjs(user.createdAt).format('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Space.Compact>
                <Space.Compact>
                  <Form.Item label="수정자">
                    <Statistic value={user.updatedBy?.name} />
                  </Form.Item>
                  <Form.Item label="수정일시">
                    <Statistic value={dayjs(user.updatedAt).format('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Space.Compact>
              </Space>
            </Col>
          </Row>
        </Form>
      </div>
      <Row align="middle" justify={'space-between'}>
        <Space>
          <Button onClick={() => navigate(-1)}>뒤로</Button>
          <Button danger type="primary" onClick={doRemove}>
            삭제
          </Button>
        </Space>
        <Button type="primary" onClick={doSave}>
          저장
        </Button>
      </Row>
    </div>
  );
};

export default Page;
