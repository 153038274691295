import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Form, Divider, Input, Row, Button, message, Flex } from 'antd';

import styles from './new.module.scss';
import API from '../../helpers/API';
import { isEmail } from '../../utils/FormChecker';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState({
    title: '',
    body: '',
    emails: '',
  });

  const handleChange = (e) => {
    setPost({
      ...post,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (post.title.trim() === '') {
      message.error('제목을 입력해 주세요.');
      return false;
    }
    if (post.body.trim() === '') {
      message.error('내용을 입력해 주세요.');
      return false;
    }

    // 잘못된 이메일 주소 색출
    const invalidEmail = post.emails
      .trim()
      .split('\n')
      .map((email) => email.trim())
      .find((email) => isEmail(email) === false);

    if (invalidEmail) {
      message.error('올바른 이메일 주소를 입력해 주세요.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      title: post.title,
      body: post.body,
      emails: post.emails
        .trim()
        .split('\n')
        .map((email) => email.trim()),
    };

    API.post('/posts', data).then(({ success }) => {
      if (success) {
        navigate('/posts');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>공지사항 신규 등록</h1>
      <div className={cx('contentWrapper')}>
        <Form layout="vertical" className={cx('form')}>
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            기본정보
          </Divider>
          <Flex vertical style={{ maxWidth: 640 }}>
            <Form.Item label="제목" required>
              <Input name="title" placeholder="제목" style={{ width: '30%' }} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="내용" required>
              <Input.TextArea
                name="body"
                placeholder="내용"
                rows={5}
                onChange={handleChange}
                className={cx('textArea')}
              />
            </Form.Item>
            <Form.Item label="수신 이메일">
              <Input.TextArea
                name="emails"
                placeholder="각 이메일 주소 줄바꿈 구분"
                rows={5}
                onChange={handleChange}
                className={cx('textArea')}
              />
            </Form.Item>
          </Flex>
        </Form>
      </div>
      <Row align="middle" justify={'space-between'}>
        <Button onClick={() => navigate(-1)}>뒤로</Button>
        <Button type="primary" onClick={doSave}>
          저장
        </Button>
      </Row>
    </div>
  );
};

export default Page;
