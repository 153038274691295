import classNames from 'classnames/bind';

import styles from './headingH1.module.scss';

const cx = classNames.bind(styles);

const HeadingH1 = ({ title }) => {
  return <h1 className={cx('title')}>{title}</h1>;
};

export default HeadingH1;
