import React, { useState } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import { useNavigate } from 'react-router-dom';
import { Table, Row, Button } from 'antd';
import commaNumber from 'comma-number';
import dayjs from 'dayjs';

import styles from './index.module.scss';
import API from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState();

  useMountEffect(() => {
    // API: 공지사항 내역 조회
    API.get('/posts').then(({ success, data }) => {
      if (success) {
        setPosts(data.posts);
      }
    });
  });

  const moveToForm = () => {
    navigate('/posts/new');
  };

  const columns = [
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
      ellipsis: true,
    },
    {
      title: '등록일시',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 170,
      align: 'center',
      render: (_, record) => {
        return dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '등록자',
      key: 'createdBy',
      dataIndex: 'createdBy',
      width: 100,
      align: 'center',
      render: (_, record) => record.createdBy.name,
    },
    {
      title: '수정일시',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      width: 170,
      align: 'center',
      render: (_, record) => dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '수정자',
      key: 'updatedBy',
      dataIndex: 'updatedBy',
      width: 100,
      align: 'center',
      render: (_, record) => record.updatedBy.name,
    },
  ];

  if (posts === undefined) return;

  return (
    <>
      <div className={cx('container')}>
        <h1 className={cx('title')}>공지사항 내역</h1>
        <Row align="middle" justify={'space-between'} className={cx('controlbar')}>
          <div className={cx('total')}>{posts && `총 ${commaNumber(posts.length)}`}건</div>
          <Button type="primary" onClick={moveToForm}>
            신규
          </Button>
        </Row>
        <Table
          dataSource={posts}
          columns={columns}
          rowKey={(record) => record.rid}
          sticky={true}
          pagination={false}
          onRow={(record) => ({
            onClick: () => navigate(`/posts/${record.rid}`),
          })}
          className={cx('table')}
        />
      </div>
    </>
  );
};

export default Page;
