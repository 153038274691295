import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Space } from 'antd';
import classNames from 'classnames/bind';

import LOGO from '../../assets/common/logo.png';

import styles from './index.module.scss';
import API, { setAuthToken } from '../../helpers/API';

const cx = classNames.bind(styles);

const LoginPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const doLogin = async (values) => {
    const { id, password } = values;
    const { success, data } = await API.post('/login', {
      id,
      password,
    });

    if (success && data.accessToken) {
      setAuthToken(data.accessToken);
      localStorage.setItem('@h2tms/role', data.role);
      navigate('/dashboard/tanks', { replace: true });
    }
  };

  return (
    <div className={cx('container')}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          id: 'admin',
          password: '123qwer@',
        }}
        onFinish={doLogin}>
        <div className={cx('box')}>
          <img className={cx('logo')} src={LOGO} alt="BTE 로그인" />
          <Space direction="vertical" className={cx('fieldsWrapper')}>
            <Form.Item name="id" label="아이디" rules={[{ required: true, message: '아이디를 입력해주세요' }]}>
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              label="비밀번호"
              rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}>
              <Input type="password" size="large" />
            </Form.Item>
            <Button htmlType="submit" type="primary" block size="large">
              Login
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;
