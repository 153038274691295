import { Button, DatePicker, Divider, Form, Input, InputNumber, Row, Space, Typography, message } from 'antd';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import API from 'helpers/API';
import { getAddress } from 'utils/AddressFinder';
import { BottomButtons, HeadingH1 } from 'components/ui';

import styles from './new.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const findAddress = () => {
    getAddress().then((data) => {
      form.setFieldValue('deliveryAddress', data.address);
    });
  };

  const doSave = (values) => {
    const data = {
      ...values,
      orderDate: moment(values.orderDate).format('YYYYMMDD'),
      status: 'RECEIVED',
      quantity: Number(values.quantity),
    };

    API.post('/orders', data).then(({ success }) => {
      if (success) {
        message.success('저장을 완료하였습니다.');
        navigate('/orders');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <Space direction="vertical" size={16} className={cx('wrapper')}>
        <HeadingH1 title={'신규 주문'} />
        <Form layout="vertical" onFinish={doSave} form={form} className={cx('form')}>
          <Typography.Text className={cx('title')} strong>
            주문정보
          </Typography.Text>
          <Row align={'top'} style={{ maxWidth: 640 }} justify={'space-between'}>
            <Space direction="vertical" style={{ width: '48%' }}>
              <Form.Item
                name={'originNo'}
                label="주문번호"
                rules={[{ required: true, message: '주문번호를 입력해주세요' }]}>
                <Input />
              </Form.Item>
              <Form.Item name={'quantity'} label="수량" rules={[{ required: true, message: '수량을 입력해주세요' }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item
                name={'customerName'}
                label="주문자명"
                rules={[{ required: true, message: '주문자명을 입력해주세요' }]}>
                <Input />
              </Form.Item>
            </Space>
            <Space direction="vertical" style={{ width: '48%' }}>
              <Form.Item
                name={'orderDate'}
                label="주문일자"
                rules={[{ required: true, message: '주문일자를 선택해주세요' }]}>
                <DatePicker placeholder="주문일자 선택" />
              </Form.Item>
              <Form.Item
                name={'customerCompany'}
                label="주문자 회사명"
                rules={[{ required: true, message: '회사명을 입력해주세요' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name={'customerPhone'}
                label="주문자 전화번호"
                rules={[{ required: true, message: '전화번호를 입력해주세요' }]}>
                <Input />
              </Form.Item>
            </Space>
          </Row>
          <Divider />
          <Typography.Text className={cx('title')} strong>
            배송정보
          </Typography.Text>
          <Row align={'top'} style={{ maxWidth: 640 }} justify={'space-between'}>
            <Space style={{ width: '48%' }} direction="vertical">
              <Form.Item
                name={'deliveryName'}
                label="수신자명"
                rules={[{ required: true, message: '수신자명을 입력해주세요' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name={'deliveryCompany'}
                label="수신자 회사명"
                rules={[{ required: true, message: '회사명을 입력해주세요' }]}>
                <Input />
              </Form.Item>
            </Space>
            <Space style={{ width: '48%' }} direction="vertical">
              <Form.Item
                name={'deliveryPhone'}
                label="수신자 전화번호"
                rules={[{ required: true, message: '전화번호를 입력해주세요' }]}>
                <Input style={{ width: 240 }} />
              </Form.Item>
              <Form.Item
                name={'deliveryAddress'}
                label="주소"
                rules={[{ required: true, message: '주소를 입력해주세요' }]}>
                <Input.Search onClick={findAddress} onSearch={findAddress} />
              </Form.Item>
              <Form.Item name={'deliveryAddressDetail'} label="상세주소">
                <Input />
              </Form.Item>
            </Space>
          </Row>
          <Typography.Text className={cx('title')} strong>
            부가정보
          </Typography.Text>
          <Form.Item name={'memo'} label="메모">
            <Input.TextArea style={{ resize: 'none', maxWidth: 840, minHeight: 150 }} />
          </Form.Item>

          <BottomButtons
            left={[
              <Button key={'backPage'} onClick={() => navigate(-1)}>
                뒤로
              </Button>,
            ]}
            right={[
              <Button key={'saveOrder'} type="primary" htmlType="submit">
                저장
              </Button>,
            ]}
          />
        </Form>
      </Space>
    </div>
  );
};

export default Page;
