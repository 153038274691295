import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button } from 'antd';
import classNames from 'classnames/bind';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import styles from './Sidebar.module.scss';
import API, { removeAuthToken } from '../../helpers/API';

const cx = classNames.bind(styles);

const MENUS = [
  {
    key: 'dashboard',
    label: '현황',
    children: [
      {
        key: 'dashboard/tanks',
        label: <Link to="/dashboard/tanks">용기 현황</Link>,
        roles: ['SYSTEM', 'ADMIN'],
      },
      { key: 'dashboard/orders', label: <Link to="/dashboard/orders">주문 현황</Link>, roles: ['SYSTEM', 'ADMIN'] },
    ],
  },
  {
    key: 'request',
    label: '접수 관리',
    children: [
      { key: 'orders', label: <Link to="/orders">주문 관리</Link>, roles: ['SYSTEM', 'ADMIN'] },
      { key: 'reclaims', label: <Link to="/reclaims">회수 관리</Link>, roles: ['SYSTEM', 'ADMIN'] },
    ],
  },
  {
    key: 'operation',
    label: '운영 관리',
    children: [{ key: 'posts', label: <Link to="/posts">공지사항 관리</Link>, roles: ['SYSTEM', 'ADMIN'] }],
  },
  {
    key: 'master',
    label: '마스터 관리',
    children: [
      { key: 'users', label: <Link to="/users">사용자 관리</Link>, roles: ['SYSTEM', 'ADMIN'] },
      { key: 'locations', label: <Link to="/locations">거점 관리</Link>, roles: ['SYSTEM', 'ADMIN'] },
      { key: 'tanks', label: <Link to="/tanks">용기 관리</Link>, roles: ['SYSTEM', 'ADMIN'] },
    ],
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hidden, setHidden] = useState(true);
  const [current, setCurrent] = useState();
  const [menus, setMenus] = useState();

  useEffect(() => {
    setHidden(localStorage.getItem('@h2tms/token') === null);
    buildMenus(localStorage.getItem('@h2tms/role'));
  }, [location]);

  // 로그아웃
  const doLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      API.post('/logout');

      removeAuthToken();
      navigate('/login');
    }
  };

  // 메뉴 구성
  const buildMenus = (role) => {
    const filteredMenus = MENUS.filter((menu) => {
      return menu.children.find((submenu) => submenu.roles.includes(role));
    });
    const nextMenus = filteredMenus.map((menu) => {
      return {
        ...menu,
        children: menu.children.filter((submenu) => submenu.roles.includes(role)),
      };
    });
    setMenus(nextMenus);
  };

  useEffect(() => {
    setHidden(localStorage.getItem('@h2tms/token') === null);
  }, [location]);

  useEffect(() => {
    setCurrent(location.pathname.slice(1));
  }, [location.pathname]);

  if (hidden) return;

  return (
    <Layout.Sider theme="dark" width={260} className={cx('container')}>
      <div className={cx('menuWrapper')}>
        <div className={cx('group')}>
          <div className={cx('logoWrapper')} onClick={() => navigate('/')}>
            <div className={cx('logo')} />
          </div>
          <div className={cx('menus')}>
            <Menu
              mode="inline"
              theme="dark"
              selectedKeys={[current, current.split('/')[0]]}
              onClick={(e) => setCurrent(e.key)}
              defaultOpenKeys={MENUS.map((menu) => menu.key)}
              items={menus}
            />
          </div>
        </div>
        <div className={cx('group')}>
          <Button size="large" type="text" className={cx('logout')} onClick={doLogout}>
            로그아웃
          </Button>
        </div>
      </div>
    </Layout.Sider>
  );
};

export default Sidebar;
