import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import useMountEffect from '@restart/hooks/useMountEffect';
import dayjs from 'dayjs';
import { Form, Divider, Input, Row, Button, Space, message, Statistic, Col, Flex } from 'antd';

import styles from './new.module.scss';
import { getAddress } from '../../utils/AddressFinder';
import API from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState();

  useMountEffect(() => {
    API.get(`/locations/${rid}`).then(({ success, data }) => {
      if (success) {
        setLocation(data);
      }
    });
  });

  const handleChange = (e) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value,
    });
  };

  const findAddress = () => {
    getAddress().then((data) => setLocation({ ...location, address: data.address }));
  };

  const doRemove = () => {
    if (!window.confirm('거점을 삭제하시겠습니까?')) {
      return;
    }

    API.delete(`/locations/${rid}`).then(({ success }) => {
      if (success) {
        navigate('/locations');
      } else {
        message.error('삭제를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    if (location.name.trim() === '') {
      message.error('거점명을 입력해 주세요.');
      return false;
    }
    if (location.address.trim() === '') {
      message.error('주소를 입력해 주세요.');
      return false;
    }
    if (location.phone.trim() === '') {
      message.error('전화번호를 입력해 주세요.');
      return false;
    }
    if (location.latitude.trim() === '' || location.longitude.trim() === '') {
      message.error('위치 정보를 입력해 주세요.');
      return false;
    }
    if (location.managerName.trim() === '') {
      message.error('담당자명을 입력해 주세요.');
      return false;
    }
    if (location.managerPhone.trim() === '') {
      message.error('담당자 전화번호를 입력해 주세요.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      name: location.name,
      address: location.address,
      addressDetail: location.addressDetail,
      phone: location.phone,
      latitude: location.latitude,
      longitude: location.longitude,
      managerName: location.managerName,
      managerPhone: location.managerPhone,
      memo: location.memo,
    };

    API.patch(`/locations/${rid}`, data).then(({ success }) => {
      if (success) {
        navigate('/locations');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  if (location === undefined) return;

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>거점 상세</h1>
      <div className={cx('contentWrapper')}>
        <Form layout="vertical">
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            기본정보
          </Divider>
          <Flex vertical style={{ maxWidth: 640 }}>
            <Form.Item label="거점명" required>
              <Input
                name="name"
                placeholder="거점명"
                value={location.name}
                onChange={handleChange}
                style={{ width: 300 }}
              />
            </Form.Item>
            <Space align="center">
              <Form.Item label="주소" required>
                <Input.Search
                  name="address"
                  value={location.address}
                  placeholder="주소"
                  readOnly
                  onClick={findAddress}
                  onSearch={findAddress}
                  style={{ width: 300 }}
                />
              </Form.Item>
              <Form.Item label="상세주소">
                <Input
                  name="addressDetail"
                  value={location.addressDetail}
                  placeholder="상세주소(선택)"
                  onChange={handleChange}
                  style={{ width: 300 }}
                />
              </Form.Item>
            </Space>
            <Form.Item label="전화번호" required>
              <Input
                name="phone"
                placeholder="거점명"
                value={location.phone}
                onChange={handleChange}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Space align="center">
              <Form.Item label="위도" required>
                <Input
                  name="latitude"
                  placeholder="위도"
                  value={location.latitude}
                  onChange={handleChange}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item label="경도" required>
                <Input
                  name="longitude"
                  placeholder="경도"
                  value={location.longitude}
                  onChange={handleChange}
                  style={{ width: 200 }}
                />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item label="담당자명" required>
                <Input
                  name="managerName"
                  placeholder="담당자명"
                  value={location.managerName}
                  onChange={handleChange}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item label="담당자 전화번호" required>
                <Input
                  name="managerPhone"
                  placeholder="담당자 전화번호"
                  value={location.managerPhone}
                  onChange={handleChange}
                  style={{ width: 200 }}
                />
              </Form.Item>
            </Space>
          </Flex>
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            부가정보
          </Divider>
          <Row align="top" style={{ maxWidth: 640 }}>
            <Col span={12}>
              <Form.Item name={'memo'} label="메모" colon={false}>
                <Input.TextArea className={cx('textArea')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <Space.Compact>
                  <Form.Item label="등록자">
                    <Statistic value={location.createdBy?.name} />
                  </Form.Item>
                  <Form.Item label="등록일시">
                    <Statistic value={dayjs(location.createdAt).format('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Space.Compact>
                <Space.Compact>
                  <Form.Item label="수정자">
                    <Statistic value={location.updatedBy?.name} />
                  </Form.Item>
                  <Form.Item label="수정일시">
                    <Statistic value={dayjs(location.updatedAt).format('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Space.Compact>
              </Space>
            </Col>
          </Row>
        </Form>
      </div>
      <Row align="middle" justify={'space-between'}>
        <Space>
          <Button onClick={() => navigate(-1)}>뒤로</Button>
          <Button danger type="primary" onClick={doRemove}>
            삭제
          </Button>
        </Space>
        <Button type="primary" onClick={doSave}>
          저장
        </Button>
      </Row>
    </div>
  );
};

export default Page;
