import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { Form, Divider, Col, Input, Row, Flex, Button, Space, message, Select, Statistic, DatePicker } from 'antd';

import styles from './new.module.scss';
import FormRowInfo from 'components/ui/FormRowInfo';
import API from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [tank, setTank] = useState({
    qrcode: '',
    useStatus: '',
    chargeStatus: '',
    locationRid: '',
    type: '',
    size: '',
    region: '',
    serialNo: '',
    manufacturer: '',
    manufacturedDate: '',
    expireDate: '',
    velveSerialNo: '',
    velveManufacturer: '',
    velveVersion: '',
    velveExpireDate: '',
    memo: '',
  });
  const [locations, setLocations] = useState();
  const [editable, setEditable] = useState(false);

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });

    // API: 용기 상세 조회
    API.get(`/tanks/${rid}`).then(({ success, data }) => {
      if (success) {
        setTank({
          ...data,
          locationRid: data.location?.rid ?? '',
          type: data.type ?? '',
          size: data.size ?? '',
          region: data.region ?? '',
          serialNo: data.serialNo ?? '',
          manufacturer: data.manufacturer ?? '',
          manufacturedDate: data.manufacturedDate ? dayjs(data.manufacturedDate, 'YYYYMMDD') : '',
          expireDate: data.expireDate ? dayjs(data.expireDate, 'YYYYMMDD') : '',
          velveSerialNo: data.velveSerialNo ?? '',
          velveManufacturer: data.velveManufacturer ?? '',
          velveVersion: data.velveVersion ?? '',
          velveExpireDate: data.velveExpireDate ? dayjs(data.velveExpireDate, 'YYYYMMDD') : '',
          memo: data.memo,
        });
        setEditable(['DRAFT', 'READY'].includes(data.useStatus));
      }
    });
  });

  // 제조일자 변경 시, 용기 충전기한, 밸브 충전기한 갱신
  useUpdateEffect(() => {
    if (tank.manufacturedDate === '') return;

    setTank({
      ...tank,
      expireDate: tank.manufacturedDate.clone().add(expireYears, 'years'),
      velveExpireDate: tank.manufacturedDate.clone().add(5, 'years'),
    });
  }, [tank.manufacturedDate]);

  const handleChange = (key, value) => {
    setTank({
      ...tank,
      [key]: value,
    });
  };

  const handleChangeUseStatus = (value) => {
    // 사용
    if (value === 'INUSE') {
      setTank({
        ...tank,
        useStatus: 'INUSE',
        chargeStatus: 'CHARGED',
        locationRid: locations?.find((location) => location.name === '주문고객').rid,
        manufacturedDate: dayjs(),
      });
    }
    // 기타
    else {
      setTank({
        ...tank,
        useStatus: value,
        chargeStatus: 'UNCHARGED',
        locationRid: '',
        manufacturedDate: dayjs(),
      });
    }
  };

  const doRemove = () => {
    if (!window.confirm('용기를 삭제하시겠습니까?')) {
      return;
    }

    API.delete('/tanks', { data: { rids: [rid] } }).then(({ success }) => {
      if (success) {
        navigate('/tanks');
      } else {
        message.error('삭제를 실패했습니다.');
      }
    });
  };

  const doRetire = () => {
    if (!window.confirm('용기를 폐기하시겠습니까?')) {
      return;
    }

    API.patch('/tanks/retire', { rids: [rid] }).then(({ success }) => {
      if (success) {
        navigate('/tanks');
      } else {
        message.error('폐기를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    // 임시 상태인 경우
    if (isDraft) {
      return true;
    }
    if (tank.locationRid === '') {
      message.error('현재 위치가 선택되지 않았습니다.');
      return false;
    }
    if (tank.type === '') {
      message.error('유형이 선택되지 않았습니다.');
      return false;
    }
    if (tank.size === '') {
      message.error('용량이 선택되지 않았습니다.');
      return false;
    }
    if (tank.region === '') {
      message.error('지역이 선택되지 않았습니다.');
      return false;
    }
    if (tank.serialNo.trim() === '') {
      message.error('용기 일련번호가 입력되지 않았습니다.');
      return false;
    }
    if (tank.manufacturer === '') {
      message.error('용기 제조사가 선택되지 않았습니다.');
      return false;
    }
    if (tank.velveSerialNo.trim() === '') {
      message.error('밸브 일련번호가 입력되지 않았습니다.');
      return false;
    }
    if (tank.velveManufacturer === '') {
      message.error('밸브 제조사가 선택되지 않았습니다.');
      return false;
    }
    if (tank.velveVersion === '') {
      message.error('밸브 버전이 선택되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      qrcode: tank.qrcode,
      useStatus: tank.useStatus,
      chargeStatus: tank.chargeStatus,
      locationRid: tank.locationRid,
      type: tank.type,
      size: tank.size,
      region: tank.region,
      serialNo: tank.serialNo,
      manufacturer: tank.manufacturer,
      manufacturedDate: tank.manufacturedDate ? tank.manufacturedDate.format('YYYYMMDD') : '',
      expireDate: tank.expireDate ? tank.expireDate.format('YYYYMMDD') : '',
      velveSerialNo: tank.velveSerialNo,
      velveManufacturer: tank.velveManufacturer,
      velveVersion: tank.velveVersion,
      velveExpireDate: tank.velveExpireDate.format('YYYYMMDD'),
      memo: tank.memo,
    };

    API.patch(`/tanks/${rid}`, data).then(({ success }) => {
      if (success) {
        navigate('/tanks');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  const isDraft = useMemo(() => tank.useStatus === 'DRAFT', [tank]);
  const isInUse = useMemo(() => tank.useStatus === 'INUSE', [tank]);
  const isReady = useMemo(() => tank.useStatus === 'READY', [tank]);
  const expireYears = useMemo(() => (tank.region === 'EU/USA' ? 20 : 15), [tank.region]);

  if (tank === undefined) return;

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>용기 상세</h1>
      <div className={cx('contentWrapper')}>
        <Form layout="vertical">
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            기본정보
          </Divider>
          <Form.Item label="QR코드">
            <Input name="qrcode" placeholder="QR코드" value={tank.qrcode} readOnly style={{ width: 120 }} />
          </Form.Item>
          <Row align="top" style={{ maxWidth: 960 }} justify={'space-between'}>
            <Col span={12}>
              <Form.Item label="가용상태" required={editable}>
                <Space>
                  <Select
                    placeholder="선택"
                    value={tank.useStatus}
                    onChange={(value) => handleChangeUseStatus(value)}
                    defaultValue="DRAFT"
                    disabled={!editable}
                    options={[
                      { value: 'DRAFT', label: '임시', disabled: true },
                      { value: 'READY', label: '준비' },
                      { value: 'INUSE', label: '사용' },
                      { value: 'INSPECTING', label: '검사' },
                      { value: 'RETIRED', label: '폐기', disabled: true },
                      { value: 'RECALL', label: '리콜' },
                      { value: 'DISABLED', label: '사용중지' },
                    ]}
                    style={{ width: 140 }}
                  />
                  {isInUse && !editable && <FormRowInfo>사용 상태 시, 임의 변경 불가</FormRowInfo>}
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="충전상태" required={isReady}>
                <Space>
                  <Select
                    placeholder="선택"
                    value={tank.chargeStatus}
                    onChange={(value) => handleChange('chargeStatus', value)}
                    disabled={!isReady}
                    options={[
                      { value: 'CHARGED', label: '충전' },
                      { value: 'UNCHARGED', label: '미충전' },
                    ]}
                    style={{ width: 140 }}
                  />
                  {isInUse && <FormRowInfo>사용 상태 시, 임의 변경 불가</FormRowInfo>}
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="현재 위치" required={!isDraft && !isInUse}>
                <Space>
                  <Select
                    placeholder="선택"
                    value={tank.locationRid || null}
                    disabled={isDraft || isInUse}
                    onChange={(value) => handleChange('locationRid', value)}
                    options={locations?.map((location, index) => {
                      const option = { value: location.rid, label: location.name };
                      if (location.name === '주문고객') {
                        option.disabled = true;
                      }

                      return option;
                    })}
                    style={{ width: 200 }}
                  />
                  {isInUse && <FormRowInfo>사용 상태 시, 임의 변경 불가</FormRowInfo>}
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="유형" required={!isDraft}>
                <Select
                  placeholder="선택"
                  value={tank.type || null}
                  disabled={isDraft}
                  onChange={(value) => handleChange('type', value)}
                  options={[
                    {
                      value: 'TYPE1',
                      label: 'TYPE1',
                    },
                    {
                      value: 'TYPE2',
                      label: 'TYPE2',
                    },
                    {
                      value: 'TYPE3',
                      label: 'TYPE3',
                    },
                    {
                      value: 'TYPE4',
                      label: 'TYPE4',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="용량" required={!isDraft}>
                <Select
                  placeholder="선택"
                  value={tank.size || null}
                  disabled={isDraft}
                  onChange={(value) => handleChange('size', value)}
                  options={[
                    {
                      value: 7,
                      label: '7L',
                    },
                    {
                      value: 9,
                      label: '9L',
                    },
                    {
                      value: 10.8,
                      label: '10.8L',
                    },
                    {
                      value: 12,
                      label: '12L',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="지역" required={!isDraft}>
                <Select
                  placeholder="선택"
                  value={tank.region || null}
                  disabled={isDraft}
                  onChange={(value) => handleChange('region', value)}
                  options={[
                    {
                      value: 'KOREA',
                      label: 'KOREA',
                    },
                    {
                      value: 'EU/USA',
                      label: 'EU/USA',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="용기 일련번호" required>
                <Input
                  name="serialNo"
                  placeholder="일련번호"
                  value={tank.serialNo || null}
                  disabled={isDraft}
                  onChange={(e) => handleChange('serialNo', e.target.value)}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item label="용기 제조사" required={!isDraft}>
                <Select
                  placeholder="선택"
                  disabled={isDraft}
                  value={tank.manufacturer || null}
                  onChange={(value) => handleChange('manufacturer', value)}
                  options={[
                    {
                      value: '태광',
                      label: '태광',
                    },
                    {
                      value: '일진',
                      label: '일진',
                    },
                    {
                      value: '시노마',
                      label: '시노마',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
              <Form.Item label="제조일자" required={!isDraft}>
                <DatePicker
                  placeholder="제조일자"
                  value={tank.manufacturedDate !== '' ? tank.manufacturedDate : !isDraft && dayjs()}
                  onChange={(value) => handleChange('manufacturedDate', value)}
                  disabled={isDraft}
                  readOnly
                />
              </Form.Item>
              <Row>
                <Form.Item label="용기 충전기한" required={!isDraft}>
                  <Space>
                    <DatePicker
                      placeholder="용기 충전기한"
                      disabled={isDraft}
                      value={tank.expireDate ? tank.expireDate : ''}
                      onChange={(value) => handleChange('expireDate', value)}
                      readOnly
                    />
                    <FormRowInfo>기본값 : 제조일자로부터 15년</FormRowInfo>
                  </Space>
                </Form.Item>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item label="밸브 일련번호" required={!isDraft}>
                <Input
                  name="velveSerialNo"
                  placeholder="밸브 일련번호"
                  value={tank.velveSerialNo}
                  onChange={(e) => handleChange('velveSerialNo', e.target.value)}
                  disabled={isDraft}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item label="밸브 제조사" required={!isDraft}>
                <Select
                  placeholder="선택"
                  disabled={isDraft}
                  value={tank.velveManufacturer || null}
                  onChange={(value) => handleChange('velveManufacturer', value)}
                  options={[
                    {
                      value: '태광',
                      label: '태광',
                    },
                    {
                      value: '영도',
                      label: '영도',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
              <Form.Item label="밸브 버전" required={!isDraft}>
                <Select
                  placeholder="선택"
                  value={tank.velveVersion || null}
                  disabled={isDraft}
                  onChange={(value) => handleChange('velveVersion', value)}
                  options={[
                    {
                      value: 'V01',
                      label: 'V01',
                    },
                    {
                      value: 'V02',
                      label: 'V02',
                    },
                    {
                      value: 'V03',
                      label: 'V03',
                    },
                    {
                      value: 'V03-1',
                      label: 'V03-1',
                    },
                    {
                      value: 'V04',
                      label: 'V04',
                    },
                    {
                      value: 'V05',
                      label: 'V05',
                    },
                    {
                      value: 'V06',
                      label: 'V06',
                    },
                    {
                      value: 'V07',
                      label: 'V07',
                    },
                    {
                      value: 'V08',
                      label: 'V08',
                    },
                    {
                      value: 'V09',
                      label: 'V09',
                    },
                    {
                      value: 'V10',
                      label: 'V10',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
              <Form.Item label="밸브 충전기한" required={!isDraft}>
                <Space>
                  <DatePicker
                    placeholder="밸브 충전기한"
                    disabled={isDraft}
                    readOnly
                    value={tank.velveExpireDate ? tank.velveExpireDate : ''}
                    onChange={(value) => handleChange('velveExpireDate', value)}
                  />
                  <FormRowInfo>기본값 : 제조일자로부터 5년</FormRowInfo>
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Statistic title="충전소" value="MS가스" valueStyle={{ fontSize: '1rem' }} />
            </Col>
            <Col span={12}>
              <Statistic title="충전횟수" value={tank.chargeCount} valueStyle={{ fontSize: '1rem' }} />
            </Col>
          </Row>
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            부가정보
          </Divider>
          <Row align="top" style={{ maxWidth: 960 }}>
            <Col span={12}>
              <Form.Item name={'memo'} label="메모" colon={false}>
                <Input.TextArea className={cx('textArea')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <Space.Compact>
                  <Form.Item label="등록자">
                    <Statistic value={tank.createdBy?.name} />
                  </Form.Item>
                  <Form.Item label="등록일시">
                    <Statistic value={dayjs(tank.createdAt).format('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Space.Compact>
                <Space.Compact>
                  <Form.Item label="수정자">
                    <Statistic value={tank.updatedBy?.name} />
                  </Form.Item>
                  <Form.Item label="수정일시">
                    <Statistic value={dayjs(tank.updatedAt).format('YYYY-MM-DD HH:mm:ss')} />
                  </Form.Item>
                </Space.Compact>
              </Space>
            </Col>
          </Row>
        </Form>
      </div>
      <Row align="middle" justify="space-between">
        <Space>
          <Button onClick={() => navigate(-1)}>뒤로</Button>
          <Button danger type="primary" onClick={doRemove}>
            삭제
          </Button>
          <Button danger type="primary" onClick={doRetire}>
            폐기
          </Button>
        </Space>
        <Button type="primary" onClick={doSave}>
          저장
        </Button>
      </Row>
    </div>
  );
};

export default Page;
