const { daum } = window;

const WIDTH = 500;
const HEIGHT = 600;

export const getAddress = () => {
  return new Promise((resolve) => {
    new daum.Postcode({
      // 팝업의 크기
      width: WIDTH,
      height: HEIGHT,

      // 주소 검색 결과 중 특정 항목을 선택했을 때 호출되는 함수
      oncomplete: (data) => resolve(data),

      // 팝업 활성화 및 팝업 상세 설정
    }).open({
      popupKey: 'addressPopup',
      popupTitle: '주소 찾기',
      left: window.innerWidth / 2 - WIDTH / 2,
      top: window.innerHeight / 2 - HEIGHT / 2,
    });
  });
};
