import axios from 'axios';

const axiosOptions = {
  baseURL: 'https://api.h2tms.bte-inc.co.kr/bo',
  headers: {
    // SOME
  },
};

const token = localStorage.getItem('@h2tms/token');
if (token) {
  axiosOptions.headers['Authorization'] = `Bearer ${token}`;
}

const axiosInstance = axios.create(axiosOptions);
axiosInstance.interceptors.response.use(
  ({ data }) => {
    // Invalid access token
    if (!data.success && data.data.message === 'invalid access token') {
      localStorage.clear();
      window.location.href = '/login';
      return;
    }

    console.debug(data);
    return data;
  },
  (error) => {
    return {
      success: false,
      data: { error },
    };
  },
);

export default axiosInstance;

export const setAuthToken = (newToken) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  localStorage.setItem('@h2tms/token', newToken);
};

export const removeAuthToken = () => {
  delete axiosInstance.defaults.headers.common['Authorization'];
  localStorage.clear();
};
