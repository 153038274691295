import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';
import { InputNumber, Select, Space, Table } from 'antd';

import styles from './card.module.scss';

const cx = classNames.bind(styles);

const ByDayCount = ({ data: tanks }) => {
  const [tankSize, setTankSize] = useState();
  const [enteredDayCount, setEnteredDayCount] = useState(25);

  const handleChangeTankSize = (value) => {
    setTankSize(Number(value));
  };

  const handleChangeDayCount = (value) => {
    const enteredValue = Number(value);

    if (!isNaN(enteredValue)) {
      setEnteredDayCount(enteredValue);
    }
  };

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '주문고객명',
    },
    {
      key: 'dayCount',
      dataIndex: 'dayCount',
      title: '보유일수',
      width: 80,
      align: 'center',
    },
  ];

  const data = useMemo(() => {
    if (tanks === undefined) return;

    // 선택된 사이즈의 용기
    const filteredTanks = tanks.filter((tank) => (tankSize ? tankSize === tank.size : true));

    // 주문고객별 최대 보유일수 객체화
    const dayCountOfCustomer = filteredTanks.reduce((acc, tank) => {
      if (tank.deliveryInfo === undefined) return acc;

      const dayCount = moment().diff(moment(tank.deliveryInfo.deliveredAt), 'day') + 1;
      const customerName = tank.deliveryInfo.deliveryCompany;

      // 입력된 보유일수 이상인 건 대상
      if (dayCount >= enteredDayCount) {
        if (acc[customerName]) {
          acc[customerName] = Math.max(acc[customerName], dayCount);
        } else {
          acc[customerName] = dayCount;
        }
      }

      return acc;
    }, {});

    // 객체 배열화
    const mappedData = Object.entries(dayCountOfCustomer).map(([name, dayCount]) => ({ name, dayCount }));

    // 주문고객명 오름차순 정렬
    mappedData.sort((a, b) => (a.name < b.name ? -1 : 1));

    return mappedData;
  }, [tanks, tankSize, enteredDayCount]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>고객별 보유일수</div>
        <Space>
          <Select
            style={{ width: 80 }}
            defaultValue={''}
            options={[
              {
                label: '전체',
                value: '',
              },
              {
                label: '7L',
                value: '7',
              },
              {
                label: '9L',
                value: '9',
              },
              {
                label: '10.8L',
                value: '10.8',
              },
              {
                label: '12L',
                value: '12',
              },
            ]}
            onChange={(value) => handleChangeTankSize(value)}
          />
          <Space size={6}>
            <InputNumber style={{ width: 60 }} value={enteredDayCount} onChange={handleChangeDayCount} maxLength={3} />
            <span className={cx('post')}>일 이상</span>
          </Space>
        </Space>
      </div>
      <div className={cx('body')}>
        <Table
          columns={columns}
          scroll={{
            x: 300,
            y: 280,
          }}
          virtual
          dataSource={data}
          pagination={false}
          rowKey={'name'}
        />
      </div>
    </div>
  );
};

export default ByDayCount;
