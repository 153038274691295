import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { Select, Table } from 'antd';
import { ResponsivePie } from '@nivo/pie';

import styles from './card.module.scss';

const cx = classNames.bind(styles);

const ByChargeStatus = ({ data: tanks }) => {
  const [tankSize, setTankSize] = useState();

  const handleChangeTankSize = (value) => {
    setTankSize(Number(value));
  };

  const columns = [
    {
      key: 'UNCHARGED',
      dataIndex: 'UNCHARGED',
      title: '미충전',
      align: 'center',
      render: (_, record) => Number(record.UNCHARGED).toLocaleString(),
    },
    {
      key: 'CHARGED',
      dataIndex: 'CHARGED',
      title: '충전',
      render: (_, record) => Number(record.CHARGED).toLocaleString(),
      align: 'center',
    },
    {
      key: 'TOTAL',
      dataIndex: 'TOTAL',
      title: '합계',
      render: (_, record) => Number(record.TOTAL).toLocaleString(),
      align: 'center',
    },
  ];

  const readyTanks = useMemo(() => {
    if (tanks === undefined) return [];

    return tanks
      .filter((tank) => tank.useStatus === 'READY')
      .filter((tank) => (tankSize ? tankSize === tank.size : true));
  }, [tanks, tankSize]);

  const tableData = useMemo(() => {
    const nextData = {
      UNCHARGED: readyTanks.filter((tank) => tank.chargeStatus === 'UNCHARGED').length,
      CHARGED: readyTanks.filter((tank) => tank.chargeStatus === 'CHARGED').length,
      TOTAL: readyTanks.length,
    };
    return [nextData];
  }, [readyTanks]);

  const chartData = useMemo(() => {
    return [
      {
        id: '미충전',
        value: readyTanks.filter((tank) => tank.chargeStatus === 'UNCHARGED').length ?? 0,
      },
      {
        id: '충전',
        value: readyTanks.filter((tank) => tank.chargeStatus === 'CHARGED').length ?? 0,
      },
    ];
  }, [readyTanks]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>준비 중 충전상태별</div>
        <div className={cx('options')}>
          <Select
            style={{ width: 120 }}
            defaultValue={''}
            options={[
              {
                label: '전체',
                value: '',
              },
              {
                label: '7L',
                value: '7',
              },
              {
                label: '9L',
                value: '9',
              },
              {
                label: '10.8L',
                value: '10.8',
              },
              {
                label: '12L',
                value: '12',
              },
            ]}
            onChange={(value) => handleChangeTankSize(value)}
          />
        </div>
      </div>
      <div className={cx('body')}>
        <Table columns={columns} dataSource={tableData} pagination={false} rowKey={'CHARGED'} />
        <div className={cx('chartWrapper')}>
          <ResponsivePie
            data={chartData}
            margin={{ top: 24, right: 0, bottom: 24, left: 0 }}
            innerRadius={0.5}
            padAngle={0.8}
            cornerRadius={4}
            colors={{ scheme: 'set1' }}
            arcLabelsTextColor="white"
            arcLinkLabelsDiagonalLength={8}
          />
        </div>
      </div>
    </div>
  );
};

export default ByChargeStatus;
