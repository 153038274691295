import React, { useState } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import { Row } from 'antd';
import dayjs from 'dayjs';

import styles from './index.module.scss';
import CountBoxes from './CountBoxes';
import Chart from './Chart';
import API from 'helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const [orders, setOrders] = useState();

  useMountEffect(() => {
    queryOrder();
  });

  const queryOrder = () => {
    API.get('/orders').then(({ success, data }) => {
      if (success) {
        setOrders(data.orders);
      }
    });
  };

  return (
    <div className={cx('container')}>
      <Row align={'middle'} justify={'space-between'}>
        <p className={cx('time')}>{dayjs().format('YYYY년 M월 D일 HH:mm 기준')}</p>
      </Row>
      <CountBoxes data={orders} />
      <Chart data={orders} />
    </div>
  );
};

export default Page;
