import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';
import useMountEffect from '@restart/hooks/useMountEffect';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Divider, Radio, Space, Statistic, Typography } from 'antd';

import API from 'helpers/API';
import { getDecodedSign } from 'utils/SignDecoder';
import { BottomButtons, HeadingH1, UnitTable } from 'components/ui';

import PrintChecklist from './PrintChecklist';
import styles from './checklist.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [terms, setTerms] = useState();
  const [order, setOrder] = useState();
  const [checklist, setChecklist] = useState();
  const [showPaper, setShowPaper] = useState(false);

  const data = useMemo(() => {
    if (terms === undefined) return;
    if (checklist === undefined) return;

    // 체크리스트 항목, 답변 매핑
    const mappedChecklist = terms.map((term) => {
      const { agreement } = checklist.checklistAnswers.find((item) => item.termRid === term.rid);

      return {
        question: term.question,
        agreement,
      };
    });

    return {
      checklist: mappedChecklist,
      sign: getDecodedSign(checklist.sign.data),
      createdBy: checklist.createdBy.name,
      createdAt: moment(checklist.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    };
  }, [checklist, terms]);

  useMountEffect(() => {
    // 체크리스트 항목 조회
    API.get('/checklist').then(({ success, data }) => {
      if (success) {
        setTerms(data);
      }
    });

    // 체크리스트 기록 조회
    API.get(`/orders/${rid}/checklist`).then(({ success, data }) => {
      if (success && data.checklistAnswers.length > 0) {
        setChecklist(data);
      }
    });

    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        setOrder(data);
      }
    });
  });

  return (
    <div className={cx('container')}>
      <HeadingH1 title={'체크리스트'} />

      <Space size={38}>
        <Statistic title="점검자" value={data?.createdBy} />
        <Statistic title="점검일시" value={data?.createdAt} />
      </Space>

      <Divider />

      <Typography.Text strong className={cx('title')}>
        점검사항
      </Typography.Text>

      <UnitTable
        rowKey="question"
        sticky={true}
        dataSource={data?.checklist || []}
        scroll={{
          x: 800,
        }}
        style={{ width: 800 }}
        columns={[
          {
            title: '항목',
            dataIndex: 'question',
            key: 'question',
            width: 500,
          },
          {
            title: '적합/부적합',
            dataIndex: 'agreement',
            key: 'agreement',
            align: 'center',
            width: 300,
            render: (_, record) => (
              <Radio.Group value={record.agreement} optionType="button">
                <Radio style={{ width: 100 }} value={true}>
                  적합
                </Radio>
                <Radio style={{ width: 100 }} value={false}>
                  부적합
                </Radio>
              </Radio.Group>
            ),
          },
        ]}
      />

      <Divider />

      <Typography.Text strong className={cx('title')}>
        서명
      </Typography.Text>

      <div className={cx('signatureWrapper')}>
        <img draggable={false} src={data?.sign} alt="signature" />
      </div>

      <BottomButtons
        left={[
          <Button key={'prevPage'} onClick={() => navigate(-1)}>
            뒤로
          </Button>,
        ]}
        right={[
          <Button key={'printPage'} type="primary" onClick={() => setShowPaper(true)}>
            출력
          </Button>,
        ]}
      />

      {showPaper && <PrintChecklist checklist={data} order={order} close={() => setShowPaper(false)} />}
    </div>
  );
};

export default Page;
