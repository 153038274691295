import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Row, Button, Space, Table } from 'antd';
import useMountEffect from '@restart/hooks/useMountEffect';
import commaNumber from 'comma-number';
import dayjs from 'dayjs';

import styles from './index.module.scss';
import { downloadExcelFile } from '../../utils/FileDownloader';
import API from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState();

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });
  });

  const moveToForm = () => {
    navigate('/locations/new');
  };

  const columns = [
    {
      title: '거점명',
      key: 'name',
      dataIndex: 'name',
      width: 180,
    },
    {
      title: '주소',
      key: 'adress',
      dataIndex: 'addres',
      ellipsis: true,
      render: (_, record) =>
        record.name === '주문고객' ? (
          <span style={{ fontStyle: 'italic', color: 'gray' }}>수요자 통합 거점</span>
        ) : (
          <>
            {record.address} {record.addressDetail}
          </>
        ),
    },
    {
      title: '등록일시',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 170,
      align: 'center',
      render: (_, record) => dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '등록자',
      key: 'createdBy',
      dataIndex: 'createdBy',
      width: 100,
      align: 'center',
      render: (_, record) => record.createdBy.name,
    },
    {
      title: '수정일시',
      key: 'updatedAt',
      dataIndex: 'createdAt',
      width: 170,
      align: 'center',
      render: (_, record) => dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '수정자',
      key: 'updatedBy',
      dataIndex: 'updatedBy',
      width: 100,
      align: 'center',
      render: (_, record) => record.updatedBy.name,
    },
  ];

  const download = () => {
    const records = locations.map((location) => {
      return {
        A: location.name,
        B: location.name === '주문고객' ? '수요자 통합 거점' : `${location.address} ${location.addressDetail}`,
        C: dayjs(location.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        D: location.createdBy.name,
        E: dayjs(location.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
        F: location.updatedBy.name,
      };
    });

    downloadExcelFile(
      [
        {
          A: '거점명',
          B: '주소',
          C: '등록일시',
          D: '등록자',
          E: '수정일시',
          F: '수정자',
        },
      ],
      records,
      `거점내역-${dayjs().format('YYYYMMDDHHmmss')}`,
    );
  };

  if (locations === undefined) return;

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>거점 내역</h1>
      <Row align="middle" justify={'space-between'} className={cx('controlbar')}>
        <div className={cx('total')}>{locations && `총 ${commaNumber(locations.length)}`}건</div>
        <Space>
          <Button type="primary" onClick={download}>
            엑셀 다운로드
          </Button>
          <Button type="primary" onClick={moveToForm}>
            신규
          </Button>
        </Space>
      </Row>
      <Table
        columns={columns}
        dataSource={locations}
        pagination={false}
        rowKey={(record) => record.rid}
        sticky={true}
        onRow={(record) => ({
          onClick: () => navigate(`/locations/${record.rid}`),
        })}
        className={cx('table')}
      />
    </div>
  );
};

export default Page;
