import React, { useState, useMemo } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { Space, message, Popover, QRCode, Row, Statistic, Typography, Divider, Button, Modal, Table } from 'antd';

import API from 'helpers/API';
import { phoneNumber } from 'utils/Formatter';
import { getOrderStatus, getTankUseStatus, getTankChargeStatus } from 'utils/CodeMapper';
import { BottomButtons, HeadingH1, UnitTable } from 'components/ui';

import styles from './tanks.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [order, setOrder] = useState();
  const [tanks, setTanks] = useState([]);
  const [orderTanks, setOrderTanks] = useState();

  const getTanks = () => {
    API.get('/tanks').then(({ success, data }) => {
      setTanks(success ? data.tanks : []);
    });
  };

  const selectTank = (tank) => {
    if (tank) {
      API.post(`/orders/${rid}/tanks/${tank.rid}`).then(({ success, data }) => {
        if (success) {
          message.success({
            key: 'addNewTank',
            content: '추가 완료되었습니다.',
          });
          setOrderTanks([...orderTanks, { tank, createdAt: moment().format('YYYY-MM-DD HH:mm:ss') }]);
          getTanks();
        } else {
          message.error('할당 가능한 용기가 아닙니다.');
        }
      });
    }
  };

  const deselectTank = (item) => {
    API.delete(`/orders/${rid}/tanks/${item.tank.rid}`).then(({ success }) => {
      if (success) {
        message.success('제거 완료 되었습니다.');
        setOrderTanks(orderTanks.filter((orderTank) => orderTank.tank.rid !== item.tank.rid));
        getTanks();
      }
    });
  };

  const filteredTanks = useMemo(() => {
    return tanks.filter((data) => data.useStatus === 'READY' && data.chargeStatus === 'CHARGED');
  }, [tanks]);

  const downloadQRCode = (id) => {
    const canvas = document.getElementById(id)?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = `${id}_QRCode.png`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useMountEffect(() => {
    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        setOrder(data);
        setOrderTanks(data.orderTanks);
      }
    });
  }, [rid]);

  useMountEffect(() => {
    getTanks();
  }, [rid]);

  return (
    <>
      <div className={cx('container')}>
        <HeadingH1 title={'용기 관리'} />

        <Typography.Text strong className={cx('title')}>
          주문정보
        </Typography.Text>

        <Row align={'top'} justify={'space-between'} className={cx('wrapper')}>
          <Statistic title="주문번호" value={`${order?.originNo}`} groupSeparator={''} />
          <Statistic title="주문일자" value={moment(order?.orderDate, 'YYYYMMDD').format('YYYY-MM-DD')} />
          <Statistic title="상태" value={getOrderStatus(order?.status)} />
          <Statistic title="수량" value={order?.quantity} />
          <Statistic title="성명" value={order?.customerName} />
          <Statistic title="회사명" value={order?.customerCompany} />
          <Statistic title="전화번호" value={phoneNumber(order?.customerPhone)} />
        </Row>

        <Divider />

        <Typography.Text strong className={cx('title')}>
          배송정보
        </Typography.Text>

        <Space size={36}>
          <Statistic title="성명" value={order?.deliveryName} />
          <Statistic title="회사명" value={order?.deliveryCompany} />
          <Statistic title="전화번호" value={phoneNumber(order?.deliveryPhone)} />
        </Space>

        <Divider />

        <Typography.Text strong className={cx('title')}>
          용기내역
        </Typography.Text>

        <Button
          disabled={order?.status === 'DELIVERED' || order?.status === 'SHIPPING'}
          style={{ width: 'fit-content' }}
          type="primary"
          onClick={() => setIsOpen((isPrev) => !isPrev)}>
          용기 추가
        </Button>

        <UnitTable
          dataSource={orderTanks}
          rowKey={(rowData) => rowData.tank.rid}
          columns={[
            {
              key: 'qrcode',
              dataIndex: 'qrcode',
              title: 'QR코드',
              width: 80,
              align: 'center',
              render: (_, record) => {
                const qrId = `${record?.tank?.rid}_QR`;
                return (
                  <Popover
                    id={qrId}
                    overlayInnerStyle={{ padding: 0 }}
                    content={<QRCode size={200} value={record.tank?.qrcode} bordered={false} />}>
                    <Button onClick={() => downloadQRCode(qrId)} icon={<DownloadOutlined />} />
                  </Popover>
                );
              },
            },
            {
              key: 'serialNo',
              dataIndex: 'serialNo',
              title: '용기 일련번호',
              render: (_, record) => <Link to={`/tanks/${record.tank?.rid}`}>{record.tank?.serialNo}</Link>,
            },
            {
              key: 'useStatus',
              dataIndex: 'useStatus',
              title: '가용상태',
              width: 80,
              align: 'center',
              render: (_, record) => getTankUseStatus(record.tank?.useStatus),
            },
            {
              key: 'chargeStatus',
              dataIndex: 'chargeStatus',
              title: '충전상태',
              width: 80,
              align: 'center',
              render: (_, record) => getTankChargeStatus(record.tank?.chargeStatus),
            },
            {
              key: 'chargeCount',
              dataIndex: 'chargeCount',
              title: '충전횟수',
              width: 80,
              align: 'right',
              render: (_, record) => Number(record.tank?.chargeCount).toLocaleString(),
            },
            {
              key: 'createdAt',
              dataIndex: 'createdAt',
              title: '할당일시',
              align: 'center',
              width: 180,
              render: (_, record) => moment(record.createdAt, 'YYYYMMDD').format('YYYY-MM-DD HH:mm:ss'),
            },
            {
              key: 'name',
              dataIndex: 'name',
              title: '할당자',
              width: 100,
              ellipsis: true,
              render: (_, record) => (record.createdBy?.name ? record.createdBy?.name : '-'),
            },
            {
              key: 'del',
              dataIndex: 'del',
              title: '제거',
              align: 'center',
              width: 80,
              render: (_, record) => (
                <Button
                  disabled={order?.status === 'DELIVERED' || order?.status === 'SHIPPING'}
                  danger
                  type="primary"
                  size="small"
                  onClick={() => deselectTank(record)}>
                  제거
                </Button>
              ),
            },
          ]}
        />

        <BottomButtons
          left={[
            <Button key={'prevMovePage'} onClick={() => navigate(-1)}>
              뒤로
            </Button>,
          ]}
        />
      </div>

      <Modal
        title="용기 추가"
        open={isOpen}
        width={1280}
        onCancel={() => setIsOpen((isPrev) => !isPrev)}
        maskClosable={false}
        footer={null}>
        <Table
          dataSource={filteredTanks}
          rowKey={'rid'}
          columns={[
            {
              key: 'serialNo',
              dataIndex: 'serialNo',
              title: '용기 일련번호',
              width: 180,
            },
            {
              key: 'useStatus',
              dataIndex: 'useStatus',
              title: '가용상태',
              align: 'center',
              width: 80,
              render: (_, record) => getTankUseStatus(record.useStatus),
            },
            {
              key: 'chargeStatus',
              dataIndex: 'chargeStatus',
              title: '충전상태',
              align: 'center',
              width: 80,
              render: (_, record) => getTankChargeStatus(record.chargeStatus),
            },
            {
              key: 'chargeCount',
              dataIndex: 'chargeCount',
              title: '충전횟수',
              width: 100,
              align: 'right',
              render: (_, record) => Number(record.chargeCount).toLocaleString(),
            },
            {
              key: 'location',
              dataIndex: 'location',
              title: '현재 위치',
              render: (_, record) => record.location?.name,
            },
            {
              key: 'add',
              dataIndex: 'add',
              align: 'center',
              title: '추가',
              width: 80,
              render: (_, record) => {
                return (
                  <Button
                    onClick={() => {
                      selectTank(record);
                      setIsOpen((isPrev) => !isPrev);
                    }}
                    type="primary">
                    추가하기
                  </Button>
                );
              },
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default Page;
