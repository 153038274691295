import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  DatePicker,
  message,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
  Statistic,
} from 'antd';

import styles from './detail.module.scss';
import API from 'helpers/API';
import { BottomButtons, HeadingH1 } from 'components/ui';
import { getOrderStatus } from 'utils/CodeMapper';
import { getAddress } from 'utils/AddressFinder';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState();
  const [form] = Form.useForm();

  useMountEffect(() => {
    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        setOrder(data);
        form.setFieldsValue({
          ...data,
          orderDate: moment(data.orderDate, 'YYYYMMDD'),
          createdAt: moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: moment(data.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          createdByUser: data.createdBy.name,
          updatedByUser: data.updatedBy.name,
          status: getOrderStatus(data.status),
        });
      }
    });
  }, [rid]);

  const findAddress = () => {
    getAddress().then((data) => {
      form.setFieldValue('deliveryAddress', data.address);
    });
  };

  const doSave = (values) => {
    const data = {
      originNo: values.originNo,
      orderDate: values.orderDate.format('YYYYMMDD'),
      status: order.status,
      quantity: Number(values.quantity),
      customerName: values.customerName,
      customerCompany: values.customerCompany,
      customerPhone: values.customerPhone,
      deliveryName: values.deliveryName,
      deliveryCompany: values.deliveryCompany,
      deliveryPhone: values.deliveryPhone,
      deliveryAddress: values.deliveryAddress,
      deliveryAddressDetail: values.deliveryAddressDetail,
      memo: values.memo,
    };

    // API: 주문 수정
    API.patch(`/orders/${rid}`, data).then(({ success }) => {
      if (success) {
        message.success('저장을 완료했습니다.');
        navigate('/orders');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <Space direction="vertical" size={16} className={cx('wrapper')}>
        <HeadingH1 title={'주문 상세'} />
        <Form layout="vertical" onFinish={doSave} form={form} className={cx('form')}>
          <Typography.Text className={cx('title')} strong>
            주문정보
          </Typography.Text>
          <Row align={'top'} style={{ maxWidth: 640 }} justify={'space-between'}>
            <Space direction="vertical" style={{ width: '48%' }}>
              <Form.Item
                name={'originNo'}
                label="주문번호"
                rules={[{ required: true, message: '주문번호를 입력해주세요' }]}>
                <Input />
              </Form.Item>
              <Space>
                <Form.Item name={'quantity'} label="수량" rules={[{ required: true, message: '수량을 입력해주세요' }]}>
                  <InputNumber />
                </Form.Item>

                <Form.Item name={'status'} label="상태">
                  <Statistic />
                </Form.Item>
              </Space>

              <Form.Item
                name={'customerName'}
                label="주문자명"
                rules={[{ required: true, message: '주문자명을 입력해주세요' }]}>
                <Input />
              </Form.Item>
            </Space>
            <Space direction="vertical" style={{ width: '48%' }}>
              <Form.Item
                name={'orderDate'}
                label="주문일자"
                rules={[{ required: true, message: '주문일자를 선택해주세요' }]}>
                <DatePicker placeholder="주문일자 선택" />
              </Form.Item>
              <Form.Item
                name={'customerCompany'}
                label="주문자 회사명"
                rules={[{ required: true, message: '회사명을 입력해주세요' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name={'customerPhone'}
                label="주문자 전화번호"
                rules={[{ required: true, message: '전화번호를 입력해주세요' }]}>
                <Input />
              </Form.Item>
            </Space>
          </Row>
          <Divider />
          <Typography.Text className={cx('title')} strong>
            배송정보
          </Typography.Text>
          <Row align={'top'} style={{ maxWidth: 640 }} justify={'space-between'}>
            <Space style={{ width: '48%' }} direction="vertical">
              <Form.Item
                name={'deliveryName'}
                label="수신자명"
                rules={[{ required: true, message: '수신자명을 입력해주세요' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name={'deliveryCompany'}
                label="수신자 회사명"
                rules={[{ required: true, message: '회사명을 입력해주세요' }]}>
                <Input />
              </Form.Item>
            </Space>
            <Space style={{ width: '48%' }} direction="vertical">
              <Form.Item
                name={'deliveryPhone'}
                label="수신자 전화번호"
                rules={[{ required: true, message: '전화번호를 입력해주세요' }]}>
                <Input style={{ width: 240 }} />
              </Form.Item>
              <Form.Item
                name={'deliveryAddress'}
                label="주소"
                rules={[{ required: true, message: '주소를 입력해주세요' }]}>
                <Input.Search onClick={findAddress} onSearch={findAddress} />
              </Form.Item>
              <Form.Item name={'deliveryAddressDetail'} label="상세주소">
                <Input />
              </Form.Item>
            </Space>
          </Row>
          <Typography.Text className={cx('title')} strong>
            부가정보
          </Typography.Text>
          <Space size={24}>
            <Form.Item name={'memo'} label="메모">
              <Input.TextArea style={{ resize: 'none', width: 300, minHeight: 150 }} />
            </Form.Item>
            <Space direction="vertical">
              <Space.Compact>
                <Form.Item label="등록자" name="createdByUser">
                  <Statistic />
                </Form.Item>
                <Form.Item label="등록일시" name="createdAt">
                  <Statistic />
                </Form.Item>
              </Space.Compact>
              <Space.Compact>
                <Form.Item label="수정자" name="updatedByUser">
                  <Statistic />
                </Form.Item>
                <Form.Item label="수정일시" name="updatedAt">
                  <Statistic />
                </Form.Item>
              </Space.Compact>
            </Space>
          </Space>

          <BottomButtons
            left={[
              <Button key={'backPage'} onClick={() => navigate(-1)}>
                뒤로
              </Button>,
            ]}
            right={[
              <Button key={'controlSerial'} type="primary" onClick={() => navigate(`/orders/${rid}/tanks`)}>
                용기 관리
              </Button>,
              <Button
                key={'controlDelivery'}
                type="primary"
                onClick={() => navigate(`/orders/${rid}/shipping`)}
                disabled={order?.orderTanks.length === 0}>
                배송 관리
              </Button>,
              <Button key={'saveOrder'} type="primary" htmlType="submit">
                저장
              </Button>,
            ]}
          />
        </Form>
      </Space>
    </div>
  );
};

export default Page;
