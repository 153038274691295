import React from 'react';
import { Layout as Container } from 'antd';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames/bind';

import Sidebar from './Sidebar';
import Main from './Main';
import styles from './Layout.module.scss';

const cx = classNames.bind(styles);

const Layout = () => {
  return (
    <Container className={cx('container')}>
      <Sidebar />
      <Main>
        <Outlet />
      </Main>
    </Container>
  );
};

export default Layout;
