import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Form, Divider, Input, Row, Button, Col, message, Space, Select, Flex } from 'antd';

import styles from './new.module.scss';
import API from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    id: '',
    password: '',
    rePassword: '',
    name: '',
    role: '',
    email: '',
    phone: '',
    memo: '',
  });

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (user.id.trim() === '') {
      message.error('아이디를 입력해 주세요.');
      return false;
    }
    if (user.password.trim() === '') {
      message.error('비밀번호를 입력해 주세요.');
      return false;
    }
    if (user.password !== user.rePassword) {
      message.error('비밀번호를 확인해 주세요.');
      return false;
    }
    if (user.name.trim() === '') {
      message.error('성명을 입력해 주세요.');
      return false;
    }
    if (user.role.trim() === '') {
      message.error('역할을 입력해 주세요.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      id: user.id,
      password: user.password,
      name: user.name,
      role: user.role,
      email: user.email,
      phone: user.phone,
      memo: user.memo,
    };

    // API: 유저 신규 등록
    API.post('/users', data).then(({ success }) => {
      if (success) {
        navigate('/users');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>사용자 신규 등록</h1>
      <div className={cx('contentWrapper')}>
        <Form layout="vertical">
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            기본정보
          </Divider>
          <Flex vertical style={{ maxWidth: 640 }}>
            <Form.Item label="아이디" required>
              <Input name="id" placeholder="아이디" onChange={handleChange} style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="비밀번호" required>
              <Space align="center">
                <Input
                  name="password"
                  placeholder="비밀번호"
                  type="password"
                  onChange={handleChange}
                  style={{ width: 300 }}
                />
                <Input
                  name="rePassword"
                  placeholder="비밀번호 확인"
                  type="password"
                  onChange={handleChange}
                  style={{ width: 300 }}
                />
              </Space>
            </Form.Item>
            <Form.Item label="성명" required>
              <Input name="name" placeholder="성명" onChange={handleChange} style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="역할" required>
              <Select
                placeholder="선택"
                name="role"
                onChange={(value) => setUser({ ...user, role: value })}
                options={[
                  { value: 'SYSTEM', label: '시스템관리자' },
                  { value: 'ADMIN', label: '통합관리자' },
                  { value: 'MANAGER', label: '충전관리자' },
                  { value: 'CHARGER', label: '충전원' },
                  { value: 'DELIVERER', label: '배송원' },
                  { value: 'SALES', label: '영업사원' },
                ]}
                style={{ width: 300 }}
              />
            </Form.Item>
            <Form.Item label="이메일">
              <Input name="email" placeholder="이메일" onChange={handleChange} style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="전화번호">
              <Input name="phone" placeholder="전화번호" onChange={handleChange} style={{ width: 300 }} />
            </Form.Item>
          </Flex>
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            부가정보
          </Divider>
          <Row style={{ maxWidth: 640 }}>
            <Col span={24}>
              <Form.Item label="메모">
                <Input.TextArea
                  name="memo"
                  placeholder="메모"
                  onChange={handleChange}
                  rows={5}
                  className={cx('textArea')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Row align="middle" justify={'space-between'}>
        <Button onClick={() => navigate(-1)}>뒤로</Button>
        <Button type="primary" onClick={doSave}>
          저장
        </Button>
      </Row>
    </div>
  );
};

export default Page;
