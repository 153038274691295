import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import commaNumber from 'comma-number';
import moment from 'moment';
import { Button, Row, Space } from 'antd';

import API from 'helpers/API';
import { getReclaimStatus } from 'utils/CodeMapper';
import { downloadExcelFile } from 'utils/FileDownloader';
import { showLoading } from 'store/loadingSlice';

import styles from './index.module.scss';
import { HeadingH1, UnitTable } from 'components/ui';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [reclaims, setReclaims] = useState();

  const download = () => {
    const records = reclaims.map((reclaim) => {
      return {
        A: getReclaimStatus(reclaim.status),
        B: reclaim.quantity,
        C: reclaim.customerName,
        D: `${reclaim.customerAddress} ${reclaim.customerAddressDetail}`,
        E: moment(reclaim.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        F: reclaim.createdBy.name,
        G: moment(reclaim.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
        H: reclaim.updatedBy.name,
      };
    });

    downloadExcelFile(
      [
        {
          A: '상태',
          B: '수량',
          C: '성명',
          D: '주소',
          E: '등록일시',
          F: '등록자',
          G: '수정일시',
          H: '수정자',
        },
      ],
      records,
      `회수내역-${moment().format('YYYYMMDDHHmmss')}`,
    );
  };

  useMountEffect(() => {
    dispatch(showLoading(true));

    // API: 회수 내역 조회
    API.get('/reclaims')
      .then(({ success, data }) => {
        if (success) {
          setTotal(data.totalCount);
          setReclaims(data.reclaims);
        }
      })
      .finally(() => dispatch(showLoading(false)));
  });

  return (
    <div className={cx('container')}>
      <HeadingH1 title="회수 내역" />

      <Row align={'middle'} justify={'space-between'}>
        <Space>
          <div className={cx('total')}>{`총 ${commaNumber(total)}`}건</div>
        </Space>
        <Space>
          <Button onClick={download} type="primary">
            엑셀 다운로드
          </Button>

          <Button type="primary" onClick={() => navigate('/reclaims/new')}>
            신규
          </Button>
        </Space>
      </Row>

      <UnitTable
        onRow={(record) => {
          return {
            onClick: () => navigate(`/reclaims/${record.rid}`),
          };
        }}
        rowKey="rid"
        columns={[
          {
            key: 'status',
            dataIndex: 'status',
            title: '상태',
            render: (_, record) => getReclaimStatus(record.status),
            width: 80,
            align: 'center',
          },
          {
            key: 'quantity',
            title: '수량',
            dataIndex: 'quantity',
            render: (_, record) => Number(record.quantity).toLocaleString(),
            width: 80,
            align: 'center',
          },
          {
            key: 'customerName',
            dataIndex: 'customerName',
            title: '성명',
            ellipsis: true,
            width: 120,
          },
          {
            key: 'customerAddress',
            dataIndex: 'customerAddress',
            title: '주소',
            ellipsis: true,
            render: (_, record) => (
              <>
                {record.customerAddress} {record.customerAddressDetail}
              </>
            ),
          },
          {
            key: 'createdBy',
            dataIndex: 'createdBy',
            title: '등록자',
            render: (_, record) => record.createdBy.name,
            width: 80,
          },
          {
            key: 'createdAt',
            dataIndex: 'createdAt',
            title: '등록일시',
            render: (_, record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            width: 160,
            align: 'center',
          },
          {
            key: 'updatedBy',
            dataIndex: 'updatedBy',
            title: '수정자',
            render: (_, record) => record.updatedBy.name,
            width: 80,
          },
          {
            key: 'updatedAt',
            dataIndex: 'updatedAt',
            title: '수정일시',
            render: (_, record) => moment(record.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
            width: 160,
            align: 'center',
          },
        ]}
        dataSource={reclaims}
      />
    </div>
  );
};

export default Page;
