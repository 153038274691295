import React from 'react';
import classNames from 'classnames/bind';
import { Layout } from 'antd';

import styles from './Main.module.scss';

const cx = classNames.bind(styles);

const Main = ({ children }) => {
  return (
    <Layout.Content className={cx('container')}>
      <div className={cx('frame')}>{children}</div>
    </Layout.Content>
  );
};

export default Main;
