import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Form, Divider, Input, Row, Button, Space, message, Flex, Col } from 'antd';

import styles from './new.module.scss';
import { getAddress } from '../../utils/AddressFinder';
import API from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState({
    name: '',
    address: '',
    addressDetail: '',
    phone: '',
    latitude: '',
    longitude: '',
    managerName: '',
    managerPhone: '',
    memo: '',
  });

  const handleChange = (e) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value,
    });
  };

  const findAddress = () => {
    getAddress().then((data) => setLocation({ ...location, address: data.address }));
  };

  const validation = () => {
    if (location.name.trim() === '') {
      message.error('거점명을 입력해 주세요.');
      return false;
    }
    if (location.address.trim() === '') {
      message.error('주소를 입력해 주세요.');
      return false;
    }
    if (location.phone.trim() === '') {
      message.error('전화번호를 입력해 주세요.');
      return false;
    }
    if (location.latitude.trim() === '' || location.longitude.trim() === '') {
      message.error('위치 정보를 입력해 주세요.');
      return false;
    }
    if (location.managerName.trim() === '') {
      message.error('담당자명을 입력해 주세요.');
      return false;
    }
    if (location.managerPhone.trim() === '') {
      message.error('담당자 전화번호를 입력해 주세요.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    const data = {
      name: location.name,
      address: location.address,
      addressDetail: location.addressDetail,
      phone: location.phone,
      latitude: location.latitude,
      longitude: location.longitude,
      managerName: location.managerName,
      managerPhone: location.managerPhone,
      memo: location.memo,
    };

    API.post('/locations', data).then(({ success }) => {
      if (success) {
        navigate('/locations');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>거점 신규 등록</h1>
      <div className={cx('contentWrapper')}>
        <Form layout="vertical">
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            기본정보
          </Divider>
          <Flex align="top" vertical style={{ maxWidth: 960 }}>
            <Form.Item label="거점명" required>
              <Input
                name="name"
                placeholder="거점명"
                value={location.name}
                onChange={handleChange}
                style={{ width: 300 }}
              />
            </Form.Item>
            <Space align="center">
              <Form.Item label="주소" required>
                <Input.Search
                  name="address"
                  value={location.address}
                  placeholder="주소"
                  readOnly
                  onClick={findAddress}
                  onSearch={findAddress}
                  style={{ width: 300 }}
                />
              </Form.Item>
              <Form.Item label=" ">
                <Input
                  name="addressDetail"
                  value={location.addressDetail}
                  placeholder="상세주소(선택)"
                  onChange={handleChange}
                  style={{ width: 300 }}
                />
              </Form.Item>
            </Space>
            <Form.Item label="전화번호" required>
              <Input
                name="phone"
                placeholder="전화번호"
                value={location.phone}
                onChange={handleChange}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Space align="center">
              <Form.Item label="위도" required>
                <Input
                  name="latitude"
                  placeholder="위도"
                  value={location.latitude}
                  onChange={handleChange}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item label="경도" required>
                <Input
                  name="longitude"
                  placeholder="경도"
                  value={location.longitude}
                  onChange={handleChange}
                  style={{ width: 200 }}
                />
              </Form.Item>
            </Space>
            <Row>
              <Space>
                <Form.Item label="담당자명" required>
                  <Input
                    name="managerName"
                    placeholder="담당자명"
                    value={location.managerName}
                    onChange={handleChange}
                    style={{ width: 200 }}
                  />
                </Form.Item>
                <Form.Item label="담당자 전화번호" required>
                  <Input
                    name="managerPhone"
                    placeholder="담당자 전화번호"
                    value={location.managerPhone}
                    onChange={handleChange}
                    style={{ width: 200 }}
                  />
                </Form.Item>
              </Space>
            </Row>
          </Flex>
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            부가정보
          </Divider>
          <Row style={{ maxWidth: 640 }}>
            <Col span={24}>
              <Form.Item label="메모">
                <Input.TextArea
                  name="memo"
                  value={location.memo}
                  placeholder="메모"
                  onChange={handleChange}
                  rows={5}
                  className={cx('textArea')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Row align="middle" justify={'space-between'}>
        <Button onClick={() => navigate(-1)}>뒤로</Button>
        <Button type="primary" onClick={doSave}>
          저장
        </Button>
      </Row>
    </div>
  );
};

export default Page;
