// 이메일 주소
export const isEmail = (email) => {
  try {
    return new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}').test(email);
  } catch (e) {
    return false;
  }
};

// UUID
export const isUUID = (uuid) => {
  try {
    return new RegExp('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}').test(uuid);
  } catch (e) {
    return false;
  }
};
