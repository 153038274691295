import React from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import ProtectedRoute from './helpers/ProtectedRoute';
import Layout from './components/layout/Layout';
import UnprotectedRoute from './helpers/UnprotectedRoute';
import Login from './pages/login';
import AutoLoign from './pages/autologin';

// 현황
import MonitorTanks from 'pages/dashboard/tanks';
import MonitorOrders from 'pages/dashboard/orders';

// 운영 관리
import PostList from './pages/posts';
import PostNew from './pages/posts/new';
import PostDetail from './pages/posts/detail';

// 주문관리
import OrderList from 'pages/orders';
import OrderNew from 'pages/orders/new';
import OrderDetail from 'pages/orders/detail';
import OrderTanks from 'pages/orders/tanks';
import OrderShipping from 'pages/orders/shipping';
import OrderChecklist from 'pages/orders/checklist';
import ReclaimList from 'pages/reclaims';
import ReclaimNew from 'pages/reclaims/new';
import ReclaimDetail from 'pages/reclaims/detail';
import ReclaimTanks from 'pages/reclaims/tanks';
import ReclaimShipping from 'pages/reclaims/shipping';
import ReclaimChecklist from 'pages/reclaims/checklist';

// 마스터 관리
import LocationList from './pages/locations';
import LocationNew from './pages/locations/new';
import LocationDetail from './pages/locations/detail';
import UserList from './pages/users';
import UserNew from './pages/users/new';
import UserDetail from './pages/users/detail';
import TankList from './pages/tanks';
import TankNew from 'pages/tanks/new';
import TankDetail from 'pages/tanks/detail';

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            cellFontSize: 13,
            cellPaddingBlock: 4,
          },
          Statistic: {
            contentFontSize: 14,
          },
        },
      }}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <UnprotectedRoute>
                <Login />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/autologin"
            element={
              <UnprotectedRoute>
                <AutoLoign />
              </UnprotectedRoute>
            }
          />
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Navigate to="/dashboard/tanks" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/tanks"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <MonitorTanks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/orders"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <MonitorOrders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <OrderList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders/new"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <OrderNew />
                </ProtectedRoute>
              }
            />

            <Route
              path="/orders/:rid"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <OrderDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders/:rid/tanks"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <OrderTanks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders/:rid/shipping"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <OrderShipping />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders/:rid/shipping/checklist"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <OrderChecklist />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reclaims"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <ReclaimList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reclaims/new"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <ReclaimNew />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reclaims/:rid"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <ReclaimDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reclaims/:rid/tanks"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <ReclaimTanks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reclaims/:rid/shipping"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <ReclaimShipping />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reclaims/:rid/shipping/checklist"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <ReclaimChecklist />
                </ProtectedRoute>
              }
            />
            <Route
              path="/posts"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <PostList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/posts/new"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <PostNew />
                </ProtectedRoute>
              }
            />
            <Route
              path="/posts/:rid"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <PostDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <UserList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users/new"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <UserNew />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users/:rid"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <UserDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/locations"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <LocationList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/locations/new"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <LocationNew />
                </ProtectedRoute>
              }
            />
            <Route
              path="/locations/:rid"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <LocationDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tanks"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <TankList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tanks/new"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <TankNew />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tanks/:rid"
              element={
                <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN']}>
                  <TankDetail />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
