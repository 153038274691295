import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { Select, Table } from 'antd';

import styles from './card.module.scss';
import { getTankUseStatus } from '../../../utils/CodeMapper';

const cx = classNames.bind(styles);

const ByUseStatus = ({ data: tanks }) => {
  const [tankSize, setTankSize] = useState();

  const handleChangeTankSize = (value) => {
    setTankSize(Number(value));
  };

  const columns = [
    {
      key: 'useStatus',
      dataIndex: 'useStatus',
      title: '가용상태',
      render: (_, record) => getTankUseStatus(record.useStatus),
    },
    {
      key: 'count',
      dataIndex: 'count',
      title: '용기수',
      width: 80,
      align: 'center',
      render: (_, record) => record.count,
    },
  ];

  const tableData = useMemo(() => {
    // // 선택된 사이즈의 용기
    const filteredTanks = tanks?.filter((tank) => (tankSize ? tankSize === tank.size : true)) ?? [];

    const nextData = filteredTanks.reduce(
      (acc, tank) => {
        acc[tank.useStatus]++;
        return acc;
      },
      {
        DRAFT: 0,
        READY: 0,
        INUSE: 0,
        INSPECTING: 0,
        RECALL: 0,
        DISABLED: 0,
        RETIRED: 0,
      },
    );

    return Object.entries(nextData).map(([key, value]) => ({
      useStatus: key,
      count: value,
    }));
  }, [tanks, tankSize]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>가용상태별</div>
        <div className={cx('options')}>
          <Select
            style={{ width: 120 }}
            defaultValue={''}
            options={[
              {
                label: '전체',
                value: '',
              },
              {
                label: '7L',
                value: '7',
              },
              {
                label: '9L',
                value: '9',
              },
              {
                label: '10.8L',
                value: '10.8',
              },
              {
                label: '12L',
                value: '12',
              },
            ]}
            onChange={(value) => handleChangeTankSize(value)}
          />
        </div>
      </div>
      <div className={cx('body')}>
        <Table
          virtual
          scroll={{
            x: 300,
            y: 280,
          }}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          rowKey={'useStatus'}
        />
      </div>
    </div>
  );
};

export default ByUseStatus;
