import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Row, DatePicker, Space, Divider } from 'antd';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';

import styles from './Chart.module.scss';
import Detail from './Detail';

dayjs.extend(isSameOrBefore);
const cx = classNames.bind(styles);

const Chart = ({ data: orders }) => {
  const [dates, setDates] = useState({ from: dayjs().startOf('month'), to: dayjs() });
  const [selectedDate, setSelectedDate] = useState();

  const handleClickChartBar = (e) => {
    setSelectedDate(e.data.date);
  };

  // 일자별 주문 수 차트 데이터 정의
  const chartData = useMemo(() => {
    setSelectedDate();

    const nextData = [];
    let currentDate = dates.from.clone();

    while (currentDate.isSameOrBefore(dates.to, 'day')) {
      const nextDate = currentDate;
      nextData.push({
        label: currentDate.format('M/D'),
        date: currentDate.format('YYYYMMDD'),
        count: orders?.filter((order) => dayjs(order.orderDate).isSame(nextDate, 'day')).length ?? 0,
      });

      currentDate = currentDate.add(1, 'day');
    }

    return nextData;
  }, [orders, dates]);

  // 주문내역 테이블 데이터 정의
  const tableData = useMemo(() => {
    return orders?.filter((order) => moment(order.orderDate).format('YYYYMMDD') === selectedDate);
  }, [orders, selectedDate]);

  return (
    <div className={cx('container')}>
      <Row align="middle" justify="space-between">
        <div className={cx('title')}>일자별 주문 수</div>
        <Space>
          <DatePicker
            allowClear={false}
            defaultValue={dates.from}
            onChange={(date) => setDates({ ...dates, from: date })}
          />
          ~
          <DatePicker
            allowClear={false}
            defaultValue={dates.to}
            onChange={(date) => setDates({ ...dates, to: date })}
          />
        </Space>
      </Row>
      <div className={cx('chartWrapper')}>
        <ResponsiveBar
          data={chartData}
          keys={['count']}
          indexBy="label"
          margin={{ top: 40, right: 40, bottom: 64, left: 64 }}
          padding={0.4}
          colors={{ scheme: 'set1' }}
          labelTextColor="white"
          valueScale={{ type: 'linear' }}
          axisLeft={{
            legend: '주문 수',
            legendPosition: 'middle',
            legendOffset: -40,
            tickSize: 1,
          }}
          axisBottom={{
            legend: '일자',
            legendPosition: 'middle',
            legendOffset: 40,
          }}
          onClick={handleClickChartBar}
        />
      </div>
      {selectedDate && (
        <>
          <Divider />
          <div className={cx('title')}>{moment(selectedDate).format('M월 D일')} 주문내역</div>
          <Detail data={tableData} />
        </>
      )}
    </div>
  );
};

export default Chart;
