import React from 'react';
import classNames from 'classnames/bind';
import { IoInformationCircleOutline } from 'react-icons/io5';

import styles from './FormRowInfo.module.scss';

const cx = classNames.bind(styles);

const FormRowInfo = ({ children }) => {
  return (
    <div className={cx('container')}>
      <IoInformationCircleOutline size={20} color="gray" />
      <div className={cx('text')}>{children}</div>
    </div>
  );
};

export default FormRowInfo;
