import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Form, Input, InputNumber, Row, Select, Space, Typography, message } from 'antd';

import API from 'helpers/API';
import { getAddress } from 'utils/AddressFinder';

import styles from './new.module.scss';
import { BottomButtons, HeadingH1 } from 'components/ui';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [locations, setLocations] = useState();
  const [customerNames, setCustomerNames] = useState();

  const findAddress = () => {
    getAddress().then((data) => form.setFieldValue('customerAddress', data.address));
  };

  const doSave = (values) => {
    const data = {
      ...values,
      quantity: Number(values.quantity),
    };

    API.post('/reclaims', data).then(({ success }) => {
      if (success) {
        message.success('저장을 완료하였습니다.');
        navigate('/reclaims');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });

    // API: 주문 내역 조회
    API.get('/orders').then(({ success, data }) => {
      if (success) {
        // 모든 고객명 배열화
        const allCustomerNames = data.orders.map((order) => order.customerName.trim());
        // 고객명 중복제거 및 오름차순 정렬
        const sortedCustomerNames = [...new Set(allCustomerNames)].sort();
        // 고객명 정의
        setCustomerNames(sortedCustomerNames);
      }
    });
  });

  return (
    <div className={cx('container')}>
      <HeadingH1 title={'신규 등록'} />

      <Form form={form} layout="vertical" onFinish={doSave} className={cx('form')}>
        <Typography.Text strong className={cx('title')}>
          회수 정보
        </Typography.Text>

        <Row align={'top'} justify={'space-between'} style={{ maxWidth: 840 }}>
          <Space style={{ width: '48%' }} direction="vertical">
            <Space>
              <Form.Item
                name="customerCompany"
                label="회사명"
                rules={[{ required: true, message: '회사명을 입력해주세요' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="customerName"
                label="고객명"
                rules={[{ required: true, message: '고객명을 선택해주세요' }]}>
                <Select
                  style={{ width: 200 }}
                  placeholder="고객명"
                  options={customerNames?.map((data) => ({ label: data, value: data })) || []}
                />
              </Form.Item>
            </Space>
            <Form.Item name="customerAddress" label="주소" rules={[{ required: true, message: '주소를 입력해주세요' }]}>
              <Input.Search onSearch={findAddress} onClick={findAddress} />
            </Form.Item>
            <Form.Item name="customerAddressDetail" label="상세주소">
              <Input />
            </Form.Item>
          </Space>
          <Space style={{ width: '48%' }} direction="vertical">
            <Space>
              <Form.Item name={'quantity'} label="수량" rules={[{ required: true, message: '수량을 입력해주세요' }]}>
                <InputNumber />
              </Form.Item>

              <Form.Item
                name="reclaimLocationRid"
                label="최종 거점"
                rules={[{ required: true, message: '최종 거점을 선택해주세요' }]}>
                <Select
                  style={{ width: 200 }}
                  placeholder="최종 거점"
                  options={locations?.map((data) => ({ label: data.name, value: data.rid })) || []}
                />
              </Form.Item>
            </Space>
            <Form.Item
              name="customerPhone"
              label="전화번호"
              rules={[{ required: true, message: '전화번호를 입력해주세요' }]}>
              <Input />
            </Form.Item>
          </Space>
        </Row>

        <Divider />

        <Typography.Text strong className={cx('title')}>
          부가 정보
        </Typography.Text>

        <Form.Item name="memo" label="메모">
          <Input.TextArea style={{ resize: 'none', maxWidth: 640, minHeight: 150 }} />
        </Form.Item>

        <BottomButtons
          left={[
            <Button key={'movePrevPage'} onClick={() => navigate(-1)}>
              뒤로
            </Button>,
          ]}
          right={[
            <Button key={'saveForm'} type="primary" htmlType="submit">
              저장
            </Button>,
          ]}
        />
      </Form>
    </div>
  );
};

export default Page;
