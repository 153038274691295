import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Row, Space, Button, Table } from 'antd';
import useMountEffect from '@restart/hooks/useMountEffect';
import commaNumber from 'comma-number';
import dayjs from 'dayjs';

import { downloadExcelFile } from 'utils/FileDownloader';
import { getUserRole } from 'utils/CodeMapper';
import API from 'helpers/API';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState();

  useMountEffect(() => {
    // API: 유저 내역 조회
    API.get('/users').then(({ success, data }) => {
      if (success) {
        setUsers(data.users);
      }
    });
  });

  const moveToForm = () => {
    navigate('/users/new');
  };

  const download = () => {
    const records = users.map((user) => {
      return {
        A: user.id,
        B: user.name,
        C: getUserRole(user.role),
        D: user.email === '' ? '-' : user.email,
        E: dayjs(user.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        F: user.createdBy.name,
        G: dayjs(user.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
        H: user.updatedBy.name,
      };
    });

    downloadExcelFile(
      [
        {
          A: '아이디',
          B: '성명',
          C: '역할',
          D: '이메일',
          E: '등록일시',
          F: '등록자',
          G: '수정일시',
          H: '수정자',
        },
      ],
      records,
      `사용자내역-${dayjs().format('YYYYMMDDHHmmss')}`,
    );
  };

  const columns = [
    {
      title: '아이디',
      key: 'id',
      dataIndex: 'id',
      width: 160,
      ellipsis: true,
    },
    {
      title: '성명',
      key: 'name',
      width: 140,
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: '역할',
      key: 'role',
      dataIndex: 'role',
      width: 120,
      align: 'center',
      render: (_, record) => getUserRole(record.role),
    },
    {
      title: '이메일',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: '등록일시',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 170,
      align: 'center',
      render: (_, record) => dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '등록자',
      key: 'createdBy',
      dataIndex: 'createdBy',
      width: 100,
      align: 'center',
      render: (_, record) => record.createdBy.name,
    },
    {
      title: '수정일시',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      width: 170,
      align: 'center',
      render: (_, record) => dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '수정자',
      key: 'updatedBy',
      dataIndex: 'updatedBy',
      width: 100,
      align: 'center',
      render: (_, record) => record.updatedBy.name,
    },
  ];

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>사용자 내역</h1>
      <Row align="middle" justify={'space-between'} className={cx('controlbar')}>
        <div className={cx('total')}>{users && `총 ${commaNumber(users.length)}`}건</div>
        <Space>
          <Button type="primary" onClick={download}>
            엑셀 다운로드
          </Button>
          <Button type="primary" onClick={moveToForm}>
            신규
          </Button>
        </Space>
      </Row>
      <Table
        columns={columns}
        dataSource={users}
        pagination={false}
        rowKey={(record) => record.rid}
        sticky={true}
        scroll={{
          x: 1280,
        }}
        onRow={(record) => ({ onClick: () => navigate(`/users/${record.rid}`) })}
        className={cx('table')}
      />
    </div>
  );
};

export default Page;
