import React from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';

import styles from './Print.module.scss';
import PrintCode from './PrintCode';

const cx = classNames.bind(styles);

const Print = ({ data: tanks, close }) => {
  useMountEffect(() => {
    window.print();
    close();
  });

  return (
    <div className={cx('container')}>
      <div className={cx('paperWrapper')}>
        <div className={cx('paper')}>
          <div className={cx('printArea')}>
            {tanks.map((tank, index) => (
              <PrintCode key={index} code={tank.qrcode} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Print;
