import React, { useRef, useState } from 'react';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import QRCode from 'qrcode';

import styles from './Codepanel.module.scss';

const cx = classNames.bind(styles);

const CodePanel = ({ code, close }) => {
  const [active, setActive] = useState(false);

  const panelRef = useRef();
  const canvasRef = useRef();

  useUpdateEffect(() => {
    if (code === null) {
      setActive(false);
      return;
    }

    panelRef.current.style.top = `${window.event.clientY}px`;
    panelRef.current.style.left = `${window.event.clientX}px`;
    QRCode.toCanvas(canvasRef.current, code);
    setActive(true);
  }, [code]);

  return (
    <div ref={panelRef} className={cx(['container', { active }])} onMouseLeave={close}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default CodePanel;
