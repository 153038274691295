import useMountEffect from '@restart/hooks/useMountEffect';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import API, { setAuthToken } from '../helpers/API';
import { showLoading } from '../store/loadingSlice';

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useMountEffect(() => {
    dispatch(showLoading(true));
    const ticket = searchParams.get('ticket') ?? '';

    API.post('/autologin', { ticket }).then(({ success, data }) => {
      dispatch(showLoading(false));

      if (success && data.accessToken) {
        setAuthToken(data.accessToken);
        localStorage.setItem('@h2tms/role', data.role);
        navigate('/', { replace: true });
      }
    });
  });

  return null;
};

export default Page;
