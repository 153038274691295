import React, { useRef } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import QRCode from 'qrcode';

import styles from './PrintCode.module.scss';

const cx = classNames.bind(styles);

const PrintCode = ({ code }) => {
  const canvasRef = useRef();

  useMountEffect(() => {
    QRCode.toCanvas(canvasRef.current, code);
  });

  return (
    <div className={cx('container')}>
      <canvas ref={canvasRef} />
      <div className={cx(['code', 'monospace'])}>{code}</div>
    </div>
  );
};

export default PrintCode;
