import React, { useState } from 'react';
import { Button, Row } from 'antd';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import moment from 'moment';

import API from 'helpers/API';
import ByUseStatus from './ByUseStatus';
import ByChargeStatus from './ByChargeStatus';
import ByLocation from './ByLocation';
import ByCustomer from './ByCustomer';
import ByDayCount from './ByDayCount';
import ByExpireDate from './ByExpireDate';
import ByInspectionDate from './ByInspectionDate';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const [tanks, setTanks] = useState();

  const queryTanks = () => {
    API.get('/tanks').then(({ success, data }) => {
      if (success) {
        setTanks(data.tanks);
      }
    });
  };

  useMountEffect(() => {
    queryTanks();
  });

  return (
    <div className={cx('container')}>
      <p className={cx('time')}>{moment().format('YYYY년 M월 D일 HH:mm 기준')}</p>

      <Row className={cx('wrapper')}>
        <ByLocation data={tanks} />
        <ByCustomer data={tanks} />
        <ByUseStatus data={tanks} />
        <ByChargeStatus data={tanks} />
        <ByDayCount data={tanks} />
        <ByExpireDate data={tanks} />
        <ByInspectionDate data={tanks} />
      </Row>
    </div>
  );
};

export default Page;
