import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import commaNumber from 'comma-number';
import moment from 'moment';
import { Select, Button, Row, Space } from 'antd';

import API from 'helpers/API';
import { getOrderStatus } from 'utils/CodeMapper';
import { downloadExcelFile } from 'utils/FileDownloader';
import { showLoading } from 'store/loadingSlice';
import { HeadingH1, UnitTable } from 'components/ui';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orders, setOrders] = useState();
  const [filters, setFilters] = useState({ orderStatus: searchParams.get('orderStatus') ?? '' });

  useEffect(() => {
    search();
  }, [location]);

  const handleChangeFilter = (info) => {
    setFilters({
      ...filters,
      orderStatus: info.value,
    });
  };

  const searchWithParams = () => {
    const nextSearchParams = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== '') {
        nextSearchParams[key] = filters[key];
      }
    });

    setSearchParams(nextSearchParams);
  };

  const search = () => {
    dispatch(showLoading(true));

    // API: 주문 내역 조회
    API.get('/orders')
      .then(({ success, data }) => {
        if (success) {
          setOrders(data.orders);
        }
      })
      .finally(() => dispatch(showLoading(false)));
  };

  const download = () => {
    const records = orders.map((order) => {
      return {
        A: order.originNo,
        B: moment(order.orderDate).format('YYYY-MM-DD'),
        C: getOrderStatus(order.status),
        D: order.quantity,
        E: order.customerName,
        F: order.deliveryName,
        G: `${order.deliveryAddress} ${order.deliveryAddressDetail}`,
        H: moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        I: order.createdBy.name,
        J: moment(order.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
        K: order.updatedBy.name,
      };
    });

    downloadExcelFile(
      [
        {
          A: '주문번호',
          B: '주문일자',
          C: '상태',
          D: '수량',
          E: '주문자명',
          F: '수신자명',
          G: '주소',
          H: '등록일시',
          I: '등록자',
          J: '수정일시',
          K: '수정자',
        },
      ],
      records,
      `주문내역-${moment().format('YYYYMMDDHHmmss')}`,
    );
  };

  const columns = [
    {
      key: 'originNo',
      dataIndex: 'originNo',
      title: '주문번호',
      ellipsis: true,
      render: (_, item) => item.originNo,
      width: 120,
    },
    {
      key: 'orderDate',
      dataIndex: 'orderDate',
      title: '주문일자',
      render: (_, item) => moment(item.orderDate, 'YYYYMMDD').format('YYYY-MM-DD'),
      width: 120,
      align: 'center',
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: '상태',
      render: (_, item) => getOrderStatus(item.status),
      width: 80,
    },
    {
      key: 'quantity',
      dataIndex: 'quantity',
      title: '수량',
      render: (_, item) => item.quantity,
      width: 80,
      align: 'center',
    },
    {
      key: 'customerName',
      dataIndex: 'customerName',
      title: '주문자명',
      render: (_, item) => <div className={cx('ellipsis')}>{item.customerName}</div>,
      width: 120,
    },
    {
      key: 'deliveryName',
      dataIndex: 'deliveryName',
      title: '수신자명',
      render: (_, item) => <div className={cx('ellipsis')}>{item.deliveryName}</div>,
      width: 120,
    },
    {
      key: 'deliveryAddress',
      dataIndex: 'deliveryAddress',
      title: '주소',
      ellipsis: true,
      width: 200,
    },
    {
      key: 'createdBy',
      dataIndex: 'createdBy',
      title: '등록자',
      render: (_, item) => item.createdBy.name,
      width: 80,
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: '등록일시',
      render: (_, item) => moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
    },
    {
      key: 'updatedBy',
      dataIndex: 'updatedBy',
      title: '수정자',
      render: (_, item) => item.updatedBy.name,
      width: 80,
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: '수정일시',
      render: (_, item) => moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
      width: 160,
      align: 'center',
    },
  ];

  const filteredOrders = useMemo(() => {
    if (orders === undefined) return;

    return orders.filter((order) => {
      const orderStatus = searchParams.get('orderStatus') ?? '';

      if (orderStatus !== '') {
        return order.status === orderStatus;
      }

      return true;
    });
  }, [orders, location]);

  return (
    <div className={cx('container')}>
      <HeadingH1 title={'주문 관리'} />

      <Row align={'middle'} justify={'space-between'}>
        <Space>
          <div className={cx('total')}>{filteredOrders && `총 ${commaNumber(filteredOrders.length)}`}건</div>
          <Select
            name="orderStatus"
            defaultValue={''}
            onChange={(v, info) => handleChangeFilter(info)}
            options={[
              { label: '전체상태', value: '' },
              { label: '주문접수', value: 'RECEIVED' },
              { label: '준비중', value: 'CHARGING' },
              { label: '배송중', value: 'SHIPPING' },
              { label: '배송완료', value: 'DELIVERED' },
              { label: '주문취소', value: 'CANCELED' },
            ]}
            style={{ width: 120 }}
          />
          <Button type="primary" onClick={searchWithParams}>
            조회
          </Button>
        </Space>
        <Space>
          <Button type="primary" onClick={download}>
            엑셀 다운로드
          </Button>
          <Button key={'addNewOrder'} type="primary" onClick={() => navigate('/orders/new')}>
            신규
          </Button>
        </Space>
      </Row>

      <UnitTable
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => navigate(`/orders/${record.rid}`),
          };
        }}
        dataSource={filteredOrders}
        rowKey={'rid'}
      />
      <div />
    </div>
  );
};

export default Page;
