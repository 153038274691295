import React from 'react';
import classNames from 'classnames/bind';
import { Table } from 'antd';

import styles from './Detail.module.scss';
import { getOrderStatus } from 'utils/CodeMapper';

const cx = classNames.bind(styles);

const Detail = ({ data: orders }) => {
  const columns = [
    {
      key: 'originNo',
      dataIndex: 'originNo',
      title: '주문번호',
      width: 100,
      align: 'center',
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: '상태',
      width: 50,
      align: 'center',
      render: (_, record) => getOrderStatus(record.status),
    },
    {
      key: 'quantity',
      dataIndex: 'quantity',
      title: '수량',
      width: 50,
      align: 'center',
    },
    {
      key: 'customerName',
      dataIndex: 'customerName',
      title: '주문자명',
      ellipsis: true,
      width: 120,
    },
    {
      key: 'deliveryName',
      dataIndex: 'deliveryName',
      title: '수신자명',
      ellipsis: true,
      width: 120,
    },
    {
      key: 'address',
      title: '주소',
      width: 200,
      ellipsis: true,
      render: (_, record) => `${record.deliveryAddress} ${record.deliveryAddressDetail ?? ''}`,
    },
  ];
  return (
    <div className={cx('container')}>
      <Table rowKey={'rid'} columns={columns} dataSource={orders} bordered pagination={false} />
    </div>
  );
};

export default Detail;
