import React, { useState, useMemo } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import { message, Popover, QRCode, Row, Statistic, Typography, Divider, Button, Modal, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import API from 'helpers/API';
import { phoneNumber } from 'utils/Formatter';
import { getReclaimStatus, getTankUseStatus, getTankChargeStatus } from 'utils/CodeMapper';
import { BottomButtons, HeadingH1, UnitTable } from 'components/ui';

import styles from './tanks.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const navigate = useNavigate();
  const [reclaim, setReclaim] = useState();
  const [reclaimTanks, setReclaimTanks] = useState();
  const [tanks, setTanks] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const getTanks = () => {
    API.get('/tanks').then(({ success, data }) => {
      setTanks(success ? data.tanks : []);
    });
  };

  // 용기 선택
  const selectTank = (tank) => {
    if (tank) {
      // API: 회수-용기 할당
      API.post(`/reclaims/${rid}/tanks/${tank.rid}`).then(({ success, data }) => {
        if (success) {
          message.success({
            key: 'addNewTank',
            content: '추가 완료되었습니다.',
          });
          setReclaimTanks([
            ...reclaimTanks,
            { tank, createdBy: { name: tank.createdBy.name }, createdAt: moment().format('YYYY-MM-DD HH:mm:ss') },
          ]);
          getTanks();
        } else {
          message.error('회수 가능한 용기가 아닙니다.');
        }
      });
    }
  };

  // 용기 해제
  const deselectTank = (item) => {
    // API: 회수-용기 삭제
    API.delete(`/reclaims/${rid}/tanks/${item.tank.rid}`).then(({ success }) => {
      if (success) {
        message.success('제거되었습니다.');
        setReclaimTanks(reclaimTanks.filter((reclaimTank) => reclaimTank.tank.rid !== item.tank.rid));
        getTanks();
      }
    });
  };

  const downloadQRCode = (id) => {
    const canvas = document.getElementById(id)?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = `${id}_QRCode.png`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const filteredTanks = useMemo(() => {
    return tanks.filter((data) => data.useStatus === 'INUSE' && data.location.name === '주문고객');
  }, [tanks]);

  useMountEffect(() => {
    API.get(`/reclaims/${rid}`).then(({ success, data }) => {
      if (success) {
        setReclaim(data);
        setReclaimTanks(data.reclaimTanks);
      }
    });

    getTanks();
  }, [rid]);

  return (
    <>
      <div className={cx('container')}>
        <HeadingH1 title={'용기 관리'} />

        <Typography.Text strong className={cx('title')}>
          회수 정보
        </Typography.Text>

        <Row align={'top'} justify={'space-between'} className={cx('wrapper')}>
          <Statistic title="상태" value={getReclaimStatus(reclaim?.status)} />
          <Statistic title="수량" value={Number(reclaim?.quantity).toLocaleString()} />
          <Statistic title="성명자" value={reclaim?.customerName} />
          <Statistic title="회사명" value={reclaim?.customerCompany} />
          <Statistic title="전화번호" value={phoneNumber(reclaim?.customerPhone)} />
          <Statistic title="주소" value={`${reclaim?.customerAddress} ${reclaim?.customerAddressDetail}`} />
        </Row>

        <Divider />

        <Typography.Text strong className={cx('title')}>
          용기 내역
        </Typography.Text>

        <Button
          disabled={reclaim?.status === 'RECLAIMED' || reclaim?.status === 'SHIPPING' || filteredTanks?.length === 0}
          style={{ width: 'fit-content' }}
          type="primary"
          onClick={() => setIsOpen((isPrev) => !isPrev)}>
          용기 추가
        </Button>

        <UnitTable
          columns={[
            {
              key: 'qrcode',
              dataIndex: 'qrcode',
              title: 'QR코드',
              width: 80,
              align: 'center',
              render: (_, record) => {
                const qrId = `${record?.tank?.rid}_QR`;
                return (
                  <Popover
                    id={qrId}
                    overlayInnerStyle={{ padding: 0 }}
                    content={<QRCode size={200} value={record.tank?.qrcode} bordered={false} />}>
                    <Button onClick={() => downloadQRCode(qrId)} icon={<DownloadOutlined />} />
                  </Popover>
                );
              },
            },
            {
              key: 'serialNo',
              dataIndex: 'serialNo',
              title: '용기 일련번호',
              render: (_, record) => <Link to={`/tanks/${record.tank?.rid}`}>{record.tank?.serialNo}</Link>,
            },
            {
              key: 'useStatus',
              dataIndex: 'useStatus',
              title: '가용상태',
              render: (_, record) => getTankUseStatus(record.tank?.useStatus),
              width: 80,
              align: 'center',
            },
            {
              key: 'chargeStatus',
              dataIndex: 'chargeStatus',
              title: '충전상태',
              render: (_, record) => getTankChargeStatus(record.tank?.chargeStatus),
              width: 80,
              align: 'center',
            },
            {
              key: 'chargeCount',
              dataIndex: 'chargeCount',
              title: '충전횟수',
              width: 80,
              align: 'right',
              render: (_, record) => Number(record.tank?.chargeCount).toLocaleString(),
            },
            {
              key: 'createdAt',
              dataIndex: 'createdAt',
              title: '할당일시',
              render: (_, record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
              width: 160,
              align: 'center',
            },
            {
              key: 'createdBy',
              title: '할당자',
              render: (_, record) => (record.createdBy ? record.createdBy.name : '-'),
              width: 80,
              ellipsis: true,
              align: 'center',
            },
            {
              key: 'delete',
              title: '제거',
              width: 80,
              align: 'center',
              render: (_, record) => (
                <Button
                  disabled={reclaim?.status === 'RECLAIMED' || reclaim?.status === 'SHIPPING'}
                  danger
                  type="primary"
                  size="small"
                  onClick={() => deselectTank(record)}>
                  제거
                </Button>
              ),
            },
          ]}
          dataSource={reclaimTanks}
          rowKey={(rowData) => rowData.tank?.rid}
        />

        <BottomButtons
          left={[
            <Button key={'prevMovePage'} onClick={() => navigate(-1)}>
              뒤로
            </Button>,
          ]}
        />
      </div>

      <Modal
        title="용기 추가"
        open={isOpen}
        width={1280}
        onCancel={() => setIsOpen((isPrev) => !isPrev)}
        maskClosable={false}
        footer={null}>
        <Table
          dataSource={filteredTanks}
          rowKey={'rid'}
          columns={[
            {
              key: 'serialNo',
              dataIndex: 'serialNo',
              title: '용기 일련번호',
              width: 180,
            },
            {
              key: 'useStatus',
              dataIndex: 'useStatus',
              title: '가용상태',
              align: 'center',
              width: 80,
              render: (_, record) => getTankUseStatus(record.useStatus),
            },
            {
              key: 'chargeStatus',
              dataIndex: 'chargeStatus',
              title: '충전상태',
              align: 'center',
              width: 80,
              render: (_, record) => getTankChargeStatus(record.chargeStatus),
            },
            {
              key: 'chargeCount',
              dataIndex: 'chargeCount',
              title: '충전횟수',
              width: 100,
              align: 'right',
              render: (_, record) => Number(record.chargeCount).toLocaleString(),
            },
            {
              key: 'location',
              dataIndex: 'location',
              title: '현재 위치',
              render: (_, record) => record.location?.name,
            },
            {
              key: 'add',
              dataIndex: 'add',
              align: 'center',
              title: '추가',
              width: 80,
              render: (_, record) => {
                const isDisabled = reclaimTanks.some((data) => data.tank.serialNo === record.serialNo);

                return (
                  <Button
                    disabled={isDisabled}
                    onClick={() => {
                      selectTank(record);
                      setIsOpen((isPrev) => !isPrev);
                    }}
                    type="primary">
                    추가하기
                  </Button>
                );
              },
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default Page;
