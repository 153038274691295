import { Spin, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';

const UnitTable = ({ ...prev }) => {
  const [height, setHeight] = useState();
  const tableRef = useRef();

  useEffect(() => {
    if (tableRef.current) setHeight(tableRef.current.clientHeight - 100);
  }, [tableRef.current]);

  return (
    <div ref={tableRef} style={{ flex: 1 }}>
      <Table
        pagination={false}
        virtual
        {...prev}
        scroll={{
          x: prev.scroll?.x || 1080,
          y: height || 1,
        }}
      />
    </div>
  );
};

export default UnitTable;
