import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Form, Divider, Col, Input, Row, Button, message, Space, Select, DatePicker } from 'antd';

import styles from 'pages/tanks/new.module.scss';
import FormRowInfo from 'components/ui/FormRowInfo';
import API from 'helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState();
  const [tank, setTank] = useState({
    qrcode: '',
    useStatus: 'DRAFT',
    chargeStatus: 'UNCHARGED',
    locationRid: '',
    type: '',
    size: '',
    region: '',
    serialNo: '',
    manufacturer: '',
    manufacturedDate: '',
    expireDate: '',
    velveSerialNo: '',
    velveManufacturer: '',
    velveVersion: '',
    velveExpireDate: '',
    memo: '',
  });

  useMountEffect(() => {
    // API: QR코드 발급
    API.get('/tanks/qrcode').then(({ success, data }) => {
      if (success) {
        setTank({
          ...tank,
          qrcode: data.qrcode,
        });
      }
    });

    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });
  });

  // 제조일자 변경 시, 용기 충전기한, 밸브 충전기한 갱신
  useUpdateEffect(() => {
    if (tank.manufacturedDate === '') return;

    setTank({
      ...tank,
      expireDate: tank.manufacturedDate.clone().add(expireYears, 'years'),
      velveExpireDate: tank.manufacturedDate.clone().add(5, 'years'),
    });
  }, [tank.manufacturedDate, tank.region]);

  const handleChange = (key, value) => {
    setTank({
      ...tank,
      [key]: value,
    });
  };

  const handleChangeUseStatus = (value) => {
    // 임시
    if (value === 'DRAFT') {
      setTank({
        ...tank,
        useStatus: 'DRAFT',
        chargeStatus: 'UNCHARGED',
        locationRid: '',
        type: '',
        size: '',
        region: '',
        manufacturer: '',
        manufacturedDate: '',
        expireDate: '',
        velveSerialNo: '',
        velveManufacturer: '',
        velveVersion: '',
        velveExpireDate: '',
      });
    }
    // 사용
    else if (value === 'INUSE') {
      setTank({
        ...tank,
        useStatus: 'INUSE',
        chargeStatus: 'CHARGED',
        locationRid: locations.find((location) => location.name === '주문고객').rid,
        manufacturedDate: dayjs(),
      });
    }
    // 기타
    else {
      setTank({
        ...tank,
        useStatus: value,
        chargeStatus: 'UNCHARGED',
        locationRid: '',
        manufacturedDate: dayjs(),
      });
    }
  };

  const validation = () => {
    // 임시 상태인 경우
    if (isDraft) {
      return true;
    }
    if (tank.locationRid === '') {
      message.error('현재 위치가 선택되지 않았습니다.');
      return false;
    }
    if (tank.type === '') {
      message.error('유형이 선택되지 않았습니다.');
      return false;
    }
    if (tank.size === '') {
      message.error('용량이 선택되지 않았습니다.');
      return false;
    }
    if (tank.region === '') {
      message.error('지역이 선택되지 않았습니다.');
      return false;
    }
    if (tank.serialNo.trim() === '') {
      message.error('용기 일련번호가 입력되지 않았습니다.');
      return false;
    }
    if (tank.manufacturer === '') {
      message.error('용기 제조사가 선택되지 않았습니다.');
      return false;
    }
    if (tank.velveSerialNo.trim() === '') {
      message.error('밸브 일련번호가 입력되지 않았습니다.');
      return false;
    }
    if (tank.velveManufacturer === '') {
      message.error('밸브 제조사가 선택되지 않았습니다.');
      return false;
    }
    if (tank.velveVersion === '') {
      message.error('밸브 버전이 선택되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) {
      return;
    }

    // 임시 등록인 경우
    if (isDraft) {
      API.post('/tanks/empty', { qrcode: tank.qrcode }).then(({ success }) => {
        if (success) {
          navigate('/tanks');
        } else {
          message.error('저장을 실패했습니다.');
        }
      });
      return;
    }

    const data = {
      qrcode: tank.qrcode,
      useStatus: tank.useStatus,
      chargeStatus: tank.chargeStatus,
      locationRid: tank.locationRid,
      type: tank.type,
      size: tank.size,
      region: tank.region,
      serialNo: tank.serialNo,
      manufacturer: tank.manufacturer,
      manufacturedDate: tank.manufacturedDate.format('YYYYMMDD'),
      expireDate: tank.expireDate.format('YYYYMMDD'),
      velveSerialNo: tank.velveSerialNo,
      velveManufacturer: tank.velveManufacturer,
      velveVersion: tank.velveVersion,
      velveExpireDate: tank.velveExpireDate.format('YYYYMMDD'),
      memo: tank.memo,
    };

    API.post('/tanks', data).then(({ success }) => {
      if (success) {
        navigate('/tanks');
      } else {
        message.error('저장을 실패했습니다.');
      }
    });
  };

  const isDraft = useMemo(() => tank.useStatus === 'DRAFT', [tank]);
  const isInUse = useMemo(() => tank.useStatus === 'INUSE', [tank]);
  const isReady = useMemo(() => tank.useStatus === 'READY', [tank]);
  const expireYears = useMemo(() => (tank.region === 'EU/USA' ? 20 : 15), [tank.region]);

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>용기 신규 등록</h1>
      <div className={cx('contentWrapper')}>
        <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
          기본정보
        </Divider>
        <Form layout="vertical">
          <Form.Item label="QR코드" required>
            <Input name="qrcode" placeholder="QR코드" value={tank.qrcode} readOnly style={{ width: 120 }} />
          </Form.Item>
          <Row align="top" style={{ maxWidth: 960 }} justify={'space-between'}>
            <Col span={12}>
              <Form.Item label="가용상태" required>
                <Space size={5}>
                  <Select
                    placeholder="선택"
                    defaultValue="DRAFT"
                    onChange={(value) => handleChangeUseStatus(value)}
                    options={[
                      { value: 'DRAFT', label: '임시' },
                      { value: 'READY', label: '준비' },
                      { value: 'INUSE', label: '사용' },
                      { value: 'INSPECTING', label: '검사' },
                      { value: 'RETIRED', label: '폐기' },
                      { value: 'RECALL', label: '리콜' },
                      { value: 'DISABLED', label: '사용중지' },
                    ]}
                    style={{ width: 140 }}
                  />
                  {isDraft && <FormRowInfo>임시 등록 후 모바일을 통해 등록 완료</FormRowInfo>}
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="충전상태" required={!isDraft && !isInUse}>
                <Select
                  placeholder="선택"
                  value={tank.chargeStatus || null}
                  onChange={(value) => handleChange('chargeStatus', value)}
                  disabled={!isReady}
                  options={[
                    { value: 'CHARGED', label: '충전' },
                    { value: 'UNCHARGED', label: '미충전' },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="현재 위치" required={!isDraft && !isInUse}>
                <Select
                  placeholder="선택"
                  value={tank.locationRid || null}
                  disabled={isDraft || isInUse}
                  onChange={(value) => handleChange('locationRid', value)}
                  options={locations?.map((location, index) => {
                    const option = { value: location.rid, label: location.name };
                    if (location.name === '주문고객') {
                      option.disabled = true;
                    }

                    return option;
                  })}
                  style={{ width: 200 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="유형" required={!isDraft}>
                <Select
                  placeholder="선택"
                  disabled={isDraft}
                  onChange={(value) => handleChange('type', value)}
                  options={[
                    {
                      value: 'TYPE1',
                      label: 'TYPE1',
                    },
                    {
                      value: 'TYPE2',
                      label: 'TYPE2',
                    },
                    {
                      value: 'TYPE3',
                      label: 'TYPE3',
                    },
                    {
                      value: 'TYPE4',
                      label: 'TYPE4',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="용량" required={!isDraft}>
                <Select
                  placeholder="선택"
                  disabled={isDraft}
                  onChange={(value) => handleChange('size', value)}
                  options={[
                    {
                      value: 7,
                      label: '7L',
                    },
                    {
                      value: 9,
                      label: '9L',
                    },
                    {
                      value: 10.8,
                      label: '10.8L',
                    },
                    {
                      value: 12,
                      label: '12L',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="지역" required={!isDraft}>
                <Select
                  placeholder="선택"
                  disabled={isDraft}
                  onChange={(value) => handleChange('region', value)}
                  options={[
                    {
                      value: 'KOREA',
                      label: 'KOREA',
                    },
                    {
                      value: 'EU/USA',
                      label: 'EU/USA',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="용기 일련번호" required={!isDraft}>
                <Input
                  name="serialNo"
                  placeholder="일련번호"
                  onChange={(e) => handleChange('serialNo', e.target.value)}
                  disabled={isDraft}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item label="용기 제조사" required={!isDraft}>
                <Select
                  placeholder="선택"
                  disabled={isDraft}
                  onChange={(value) => handleChange('manufacturer', value)}
                  options={[
                    {
                      value: '태광',
                      label: '태광',
                    },
                    {
                      value: '일진',
                      label: '일진',
                    },
                    {
                      value: '시노마',
                      label: '시노마',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
              <Form.Item label="제조일자" required={!isDraft}>
                <DatePicker
                  placeholder="제조일자"
                  value={tank.manufacturedDate !== '' ? tank.manufacturedDate : !isDraft && dayjs()}
                  onChange={(value) => handleChange('manufacturedDate', value)}
                  style={{ width: 140 }}
                  disabled={isDraft}
                />
              </Form.Item>
              <Form.Item label="용기 충전기한" required={!isDraft}>
                <Space>
                  <DatePicker
                    placeholder="용기 충전기한"
                    disabled={isDraft}
                    value={tank.expireDate ? tank.expireDate : ''}
                    onChange={(value) => handleChange('expireDate', value)}
                    style={{ width: 140 }}
                  />
                  <FormRowInfo>기본값 : 제조일자로부터 15년</FormRowInfo>
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="밸브 일련번호" required={!isDraft}>
                <Input
                  name="velveSerialNo"
                  placeholder="밸브 일련번호"
                  onChange={(e) => handleChange('velveSerialNo', e.target.value)}
                  disabled={isDraft}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item label="밸브 제조사" required={!isDraft}>
                <Select
                  placeholder="선택"
                  disabled={isDraft}
                  onChange={(value) => handleChange('velveManufacturer', value)}
                  options={[
                    {
                      value: '태광',
                      label: '태광',
                    },
                    {
                      value: '영도',
                      label: '영도',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
              <Form.Item label="밸브 버전" required={!isDraft}>
                <Select
                  placeholder="선택"
                  disabled={isDraft}
                  onChange={(value) => handleChange('velveVersion', value)}
                  options={[
                    {
                      value: 'V01',
                      label: 'V01',
                    },
                    {
                      value: 'V02',
                      label: 'V02',
                    },
                    {
                      value: 'V03',
                      label: 'V03',
                    },
                    {
                      value: 'V03-1',
                      label: 'V03-1',
                    },
                    {
                      value: 'V04',
                      label: 'V04',
                    },
                    {
                      value: 'V05',
                      label: 'V05',
                    },
                    {
                      value: 'V06',
                      label: 'V06',
                    },
                    {
                      value: 'V07',
                      label: 'V07',
                    },
                    {
                      value: 'V08',
                      label: 'V08',
                    },
                    {
                      value: 'V09',
                      label: 'V09',
                    },
                    {
                      value: 'V10',
                      label: 'V10',
                    },
                  ]}
                  style={{ width: 140 }}
                />
              </Form.Item>
              <Form.Item label="밸브 충전기한" required={!isDraft}>
                <Space>
                  <DatePicker
                    placeholder="밸브 충전기한"
                    disabled={isDraft}
                    value={tank.velveExpireDate ? tank.velveExpireDate : ''}
                    onChange={(value) => handleChange('velveExpireDate', value)}
                    style={{ width: 140 }}
                  />
                  <FormRowInfo>기본값 : 제조일자로부터 5년</FormRowInfo>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left" orientationMargin={0} className={cx('divider')}>
            부가정보
          </Divider>
          <Row align="top" style={{ maxWidth: 960 }} justify={'space-between'}>
            <Col span={24}>
              <Form.Item label="메모">
                <Input.TextArea
                  name="memo"
                  placeholder="메모"
                  onChange={(e) => handleChange('memo', e.target.value)}
                  rows={5}
                  className={cx('textArea')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Row align="middle" justify="space-between">
        <Button onClick={() => navigate(-1)}>뒤로</Button>
        <Button type="primary" onClick={doSave}>
          저장
        </Button>
      </Row>
    </div>
  );
};

export default Page;
