import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

export const downloadExcelFile = (columns, data, name, origin = 'A2') => {
  // step 1. workbook 생성
  const workbook = XLSX.utils.book_new();
  // step 2. worksheet 생성
  const worksheet = XLSX.utils.json_to_sheet(columns, { skipHeader: true });
  XLSX.utils.sheet_add_json(worksheet, data, { skipHeader: true, origin });
  // step 3. workbook에 새로 만든 worksheet에 이름을 준다.
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet');
  // step 4. 엑셀 파일 만들기
  const workbookExport = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  // step 5. 엑셀 파일 내보내기
  FileSaver.saveAs(new Blob([s2ab(workbookExport)], { type: 'application/octet-stream' }), `${name}.xlsx`);
};

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
  const view = new Uint8Array(buf); // create uint8array as viewer
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; // convert to octet
  return buf;
};
